import styled, { css } from 'styled-components/macro';


import { alignCenter, alignStart, flex, justifyCenter, wrap } from '../../../styled/css';
import { device } from '../../../styled/mbreakpoints';

const FlexBlock = styled.div`
    ${ flex };
    ${ wrap };
    ${ alignCenter };
    flex-direction:column;
    margin-bottom: var(--margin-regular-x);
    ${ p => p.flexStart && alignStart };
    ${ p => p.justifyCenter && justifyCenter };
    @media ${ device.tablet } { 
        flex-direction:row;
      }
`;

const SectionHero = styled.section`

    padding-top: 2.7rem;
    padding-bottom: 1.2rem;
    margin-left:-2px;
    @media ${ device.tablet } { 
        padding-top: 4rem;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin:auto;
    }

    @media ${ device.tablet } { 
        min-height: 43.5rem;
        height:0;
    }

    @media (max-width:767px) { 
        img {
            display:none;
        }
    }


${({bg}) => bg && css`
        
        background-image:url(${bg});
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;
        
        ${({usability}) => usability && css`

            ${({bg2}) => bg2 && css`
                background-image:url(${bg2}),url(${bg});
                background-position:left center, center center;
                background-repeat: no-repeat, no-repeat;
                background-size:auto,cover;
                @media (max-width:767px) { 
                    background-image:url(${bg});
                    background-size:cover;
                }
            `} ;
        
        `};
        ${({roadmap}) => roadmap && css`

            ${({bg2}) => bg2 && css`
                background-image:url(${bg2}),url(${bg});
                background-position:left center, center center;
                background-repeat: no-repeat, no-repeat;
                background-size:77rem,cover;
                @media (max-width:767px) { 
                    background-image:url(${bg});
                    background-size:cover;
                }
            `} ;
        
        `};
    `};

`;

const Section = styled.section`
    padding-top: 2.7rem;
    padding-bottom: 1.2rem;
    background-color:${({grayed}) => grayed ? 'rgba(241, 244, 244, 0.77)' : '#fff'};
    @media ${ device.tablet } { 
        padding-top: 4rem;
        padding-bottom: 2.2rem;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin:auto;
    }

    
    ${({alternative}) => alternative && css`

    img {
        max-width: 28rem;
        height: auto;
        display: block;
        margin:auto;
        width: 100%;
    }
`};

`;

const PhaseInfo = styled.div`
    background: #FFFFFF;
    border-radius: 34px;
    overflow:hidden;
    padding:2rem;
    margin-top:2rem;
    @media ${ device.tablet } { 
        margin-top:0;
    }
`;

const PhaseInfoProgress = styled.div`

    border:solid 1px var(--brand-secondary);
    padding:0.5rem;
    background:#fff;
    
`;

const PhaseInfoProgressWrapper = styled.div`

    background:rgba(50, 213, 185, 0.32);
    height:37px;

`;

const PhaseInfoProgressInner = styled.div`

    height:100%;
    background:var(--brand-secondary);
    width:${({$progress}) => `${$progress}%` || '0'};
    
`;

const PhaseMilestones = styled.div`
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin: 0.5rem 0;
`;

const CardElements = styled.div`
    background: #F1F4F4;
    border-radius: 16px;
    padding:3rem 2rem;
    margin-bottom:2rem;
    min-height:calc(100% - 2rem);
    position:relative;
    @media ${ device.laptop } { 
        margin-bottom:0;
        min-height:100%;
    }
    
`;

const CardIndex = styled.div`
    position:absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    background:var(--brand-primary);
    font-family:var(--font-primary);
    color:#fff;
`;

const CheckerRow = styled.div`
    display:flex;
    align-items:center;
    margin-bottom:1rem;
    width:100%;
    &:last-child {
        margin-bottom:0;
    }
`;

const CheckerIconCol = styled.div`

    flex: 0 0 1.3rem;
    height: 1.3rem;
    border:1px solid var(--brand-secondary);
    margin-right:1rem;
    display:flex;
    i {
        margin:auto;
        width:auto;
        height:auto;
        color: var(--brand-secondary);
    }
`;

const CheckerTextCol = styled.div`

    flex: 1;

`;

const CardStaff = styled.div`

    padding:2rem 0rem 0rem 0rem;
    margin-bottom:2rem;
    min-height:calc(100% - 2rem);
    @media ${ device.laptop } { 
        margin-bottom:0;
        min-height:100%;
    }
    display:flex;
    flex-direction:column;

`;

const CardStaffAvatar = styled.div`
        width: 18rem;
        padding-top: 18rem;
        height: 0;
        max-width: 80%;
        margin: 0 auto;
        background: red;
        z-index: 2;
        position: relative;
        img {
            width:100%;
            height:100%;
            object-fit:cover;
        }

        div {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
        }
`;

const CardStaffContent = styled.div`
    background: #F1F4F4;
    border-radius: 16px;
    padding:5rem 2rem 2rem;
    overflow:hidden;
    z-index:1;
    position:relative;
    top:-2rem;
    flex-grow:1;
`;

const LandingWrap = styled.div`
        min-height:100%;
`;

export {
    FlexBlock,
    Section,
    PhaseInfo,
    CardElements,
    PhaseInfoProgress,
    PhaseInfoProgressWrapper,
    PhaseInfoProgressInner,
    CheckerRow,
    CheckerIconCol,
    CheckerTextCol,
    SectionHero,
    CardStaff,
    CardStaffContent,
    CardStaffAvatar,
    LandingWrap,
    CardIndex,
    PhaseMilestones
   
};