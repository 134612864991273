import React, { Component } from 'react';
import { LandingWrap } from '../../landing/styled';
import Nav from '../../../components/nav/Nav';
import { Inner, Separator } from '../../../styled/wrappers';
import { Footer } from '../../../components/footer/Footer';
import I18n from '../../../../i18n';

class LegalCookies extends Component {
    render() {
        return (
            <>
                <LandingWrap>
                    <Nav {...this.props} active="" />
                    <Inner>
                        <Separator xL />
                        <p className="heading-2">{I18n.t('cookies.cookiesPolicyHere')}</p>
                        <p className="paragraph">{I18n.t('cookies.lectureTime')}</p>
                        <p className="paragraphregular" dangerouslySetInnerHTML={{__html : I18n.t('cookies.contentCookies')}}></p>
                    </Inner>
                </LandingWrap>
                <Footer />
            </>
        );
    }
}

export default LegalCookies;