import React, { Component } from 'react'
import Nav from '../../../../components/nav/Nav';

import { ButtonWrapper, FlexWrapper, Inner, Separator } from '../../../../styled/wrappers';
import { LandingWrap, Section, SectionHero } from '../../styled';
import HeroImage from '../../../../assets/img/pedagogy.png';
import OButton from '../../../../styled/button';
import CpImage from '../../../../assets/img/practica_personalizada.png';
import CImage from '../../../../assets/img/context.png';
import CRmage from '../../../../assets/img/yogabot_realidad.png';
import BgHero from '../../../../assets/img/fondo_ecosystem.jpg';
import I18n from '../../../../../i18n';
import { Footer } from '../../../../components/footer/Footer';


class Pedagogy extends Component {

    render() {
        
        return (
            <LandingWrap>
              <Nav {...this.props} active="pedagogy"/>
                <SectionHero bg={BgHero}>
                    <Inner hero>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={HeroImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 ">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-1 white">{I18n.t('landing.pedagogy.section1.title')}</p>
                                    <p className="heading-2 regular white">{I18n.t('landing.pedagogy.section1.subtitle')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton terciary upper color="#fff" size="large">
                                            <a className="white" style={{display:'block'}} href="https://academianexoyoga.com/ " target="_blank" rel="noopener noreferrer" alt="academianexoyoga">{I18n.t('buttons.actions.startNow')}</a>
                                        </OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </SectionHero>
                <Section grayed>
                    <Inner forRead>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 regular alignCenter" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section2.p1')}}></p>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={CpImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.pedagogy.section3.title')}</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section3.p1')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section3.p2')}}></p>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                                <img src={CImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.pedagogy.section4.title')}</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section4.p1')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section4.p2')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section4.p3')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html:I18n.t('landing.pedagogy.section4.p4')}}></p>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner forRead>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-2 alignCenter">{I18n.t('landing.pedagogy.section5.title')}</p>
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="paragraph regular">{I18n.t('landing.pedagogy.section5.p1')}</p>
                            </div>
                        </div>
                    </Inner>
                    <Inner >
                    <Separator />
                        <div className="row">
                            <div className="col-sm-12 offset-md-3 col-md-6 offset-lg-4 col-lg-4 offset-xl-4 col-xl-4">
                                <img src={CRmage} alt="" />
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Footer />   
            </LandingWrap>
        );

    }

}

export default Pedagogy