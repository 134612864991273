import { css } from 'styled-components';

const flexbox = css`display:flex;`;
const flexWrap = css`flex-wrap:wrap;`;
const justifySpaceBetween = css`justify-content:space-between`;

const paddSeparator = css`
    padding-bottom:20px;
    @media (max-width:420px) {
        padding-bottom:10px;
    }
`;

const paddingAuthBox = css`
    padding: 20px;
    @media (min-width: 420px ) {
        padding: 30px;
    }
    @media (min-width: 720px ) {
        padding: 50px 40px;
    }
`;

const ObjFit = css`
    width:100%;
    height:100%;
    object-fit:contain;
`;

const AlignCenter = css `align-items:center;`;
const JustifyCenter = css `justify-content:center;`

const XYCenter = css`
    ${AlignCenter};
    ${JustifyCenter};
`;

const PrimaryFont = css`font-family:var(--font-primary), sans-serif;`;
const SecondaryFont = css`font-family:var(--font-secondary), sans-serif;`;

export {
    flexbox,
    flexWrap,
    justifySpaceBetween,
    AlignCenter,
    JustifyCenter,
    XYCenter,
    ObjFit,
    PrimaryFont,
    paddSeparator,
    paddingAuthBox,
    SecondaryFont
}