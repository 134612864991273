//libraries & components libraries
import React from 'react';

//components
import OButton from '../../../styled/button';

//styled components
import { AuthBox, AuthCol, AuthRow} from '../styled';
import { AuthHeader, AuthSeparator, AuthTitle } from '../../auth/styled';
import I18n from '../../../../i18n';

class RecoveryStep3 extends React.Component {

    render() {

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{I18n.t('recovery.restoreCorrectMessageFirst')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className="paragraph regular">{ I18n.t("recovery.restoreCorrectMessageSecond") }</p>
                        <AuthSeparator />
                        <OButton upper type="button" fluid color="#FFF" terciary onClick={()=> this.props.history.push(`/auth/login`)}>
                            <span>{I18n.t('buttons.actions.finalize')}</span>
                        </OButton>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
            
        );

    }

}

export default RecoveryStep3;