import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import loader from './loader';
import genericModal from './genericModal';
import auth from './auth';

export default combineReducers({
    form,
    auth,
    loader,
    genericModal
});