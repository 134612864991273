import styled from 'styled-components';

const Cookies = styled.div`
    position:fixed;
    bottom:0;
    left:0;
    right:0;
    padding:1.2rem 1rem;
    background:#F1F4F4;
    z-index:5;
`;

const AcceptCookieBtn = styled.button`

    background:transparent;
    outline:0;
    border:none;
    position:absolute;
    right:7px;
    top:10px;
    cursor:pointer;
    i {
        &:before{
            color:var(--brand-primary);
        }
    }
`;
export {
    Cookies,
    AcceptCookieBtn
}