import React from 'react';
import { Checkbox as SemanticCheckbox, Form } from 'semantic-ui-react';

class CustomCheckBox extends React.Component {

    render() {

        return (
            <Form.Field className={ this.props.fieldClasses }>
                <div className="inline field">
                    <SemanticCheckbox
                        {...this.props.config}
                        toggle={ this.props.toggle || false }
                        label={ this.props.label } 
                        checked={ this.props.input.value || false }
                        onChange={ (e, v) => { this.props.input.onChange(v.checked) } } />
                </div>
            </Form.Field>
        );

    }
    
};

export default CustomCheckBox;