import React, { Component } from 'react';
import I18n from '../../../i18n';
import _ from 'lodash';
import { Icon } from 'semantic-ui-react';
import BottomNav from '../../components/bottomNav/BottomNav';
import PanelNav from '../../components/nav/PanelNav';
import { Wrapper, Inner, Separator } from '../../styled/wrappers';
import { AffiliateCard } from './styled';
import PaymentModel from '../../../models/payments';
import { NumericFormat } from '../../components/numericFormat/NumericFormat';
import Moment from 'moment';

class PanelAffiliates extends Component {

    constructor(props){
        super(props);

        this.state = {
            blockStats : {},
            groupedCommissions: []
        }
    }

    componentDidMount(){

        this.getData();

    }


    getData = async () => {

        try {
            
            const commissions = await PaymentModel.getAffiliateCommissions();
            let blockStats = _.reduce(commissions.data, (acc, el) => ({
                ...acc,
                [el.concept]: acc[el.concept] ? (acc[el.concept] + el.amount) : el.amount
            }), {});

            let groupedCommissions = _.chain(commissions.data)
            .map(item => {
                return {
                    ...item,
                    momentDate: parseInt(Moment(item.createdAt).format('YYYYMM'))
                }
    
            })
            .orderBy(['momentDate'],['asc'])
            .groupBy('momentDate')
            .map((value, key) => {
                return {
                    [key]: _.reduce(value, (acc, el) => ({
                        ...acc,
                        [el.concept]: acc[el.concept] ? (acc[el.concept] + el.amount) : el.amount
                    }), {})
                }
            })
            .value()

            this.setState({blockStats, commissions: commissions.data, groupedCommissions});

        } catch (error) {

            console.error('error', error);

        }

    }

    formatDate = _dateToFormat => {

        let dateToFormat = _dateToFormat.join();
        let year = dateToFormat.substring(0, 4);
        let month = dateToFormat.substring(4, dateToFormat.length);
        return I18n.t(`months.${month}`,{year});

    }

    render() {

        const { blockStats, groupedCommissions } = this.state;

        return (
            <Wrapper>
                <PanelNav active="affiliates" />
                <Inner>
                    <Separator  />
                    <div style={{textAlign: "right"}}>
                        <a className="brand-primary" target="_blank" rel="noopener noreferrer" href="https://wiki.yogabot.io/afiliados/imagenes-para-compartir"><Icon name="info circle" size="large" /><span className="paragraph regular">{I18n.t('panel.affiliates.promotionResources')}</span></a>
                    </div>
                    <Separator xL />
                    <p className="heading-3 alignCenter">{I18n.t('panel.affiliates.title')}</p>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <AffiliateCard $withBg>
                                <span className="paragraph regular white">{I18n.t('panel.affiliates.cards.c1.text')}</span>
                                <span className="heading-2 white">{blockStats['ygb-affiliate-click'] || 0}</span>
                            </AffiliateCard>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <AffiliateCard $withBg>
                                <span className="paragraph regular white">{I18n.t('panel.affiliates.cards.c2.text')}</span>
                                <span className="heading-2 white">{blockStats['ygb-affiliate-register'] || 0}</span>
                            </AffiliateCard>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <AffiliateCard $withBg>
                                <span className="paragraph regular white">{I18n.t('panel.affiliates.cards.c3.text')}</span>
                                <span className="heading-2 white">{<NumericFormat value={(blockStats['ygb-affiliate-first-buy']  || 0) * 5} type="token" /> }</span>
                            </AffiliateCard>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                            <AffiliateCard $withBg>
                                <span className="paragraph regular white">{I18n.t('panel.affiliates.cards.c4.text')}</span>
                                <span className="heading-2 white">{<NumericFormat value={(blockStats['ygb-affiliate-first-buy'] || 0)} type="token" />}</span>
                            </AffiliateCard>
                        </div>
                    </div>
                    <Separator  />
                    <hr />
                    <Separator  />
                    <div className="row">
                        {(groupedCommissions || []).map(commission => (
                            <React.Fragment key={Object.keys(commission)}>
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <p className="paragraph">{this.formatDate(Object.keys(commission))}</p>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <AffiliateCard $bordered>
                                        <span className="paragraph regular ">{I18n.t('panel.affiliates.cards.c1.text')}</span>
                                        <span className="heading-2 ">{commission[Object.keys(commission)]['ygb-affiliate-click'] || 0}</span>
                                    </AffiliateCard>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <AffiliateCard $bordered>
                                        <span className="paragraph regular ">{I18n.t('panel.affiliates.cards.c2.text')}</span>
                                        <span className="heading-2 ">{commission[Object.keys(commission)]['ygb-affiliate-register'] || 0}</span>
                                    </AffiliateCard>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <AffiliateCard $bordered>
                                        <span className="paragraph regular ">{I18n.t('panel.affiliates.cards.c3.text')}</span>
                                        <span className="heading-2 ">{<NumericFormat value={(commission[Object.keys(commission)]['ygb-affiliate-first-buy'] || 0) * 5} type="token" />}</span>
                                    </AffiliateCard>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <AffiliateCard $bordered>
                                        <span className="paragraph regular ">{I18n.t('panel.affiliates.cards.c4.text')}</span>
                                        <span className="heading-2 ">{<NumericFormat value={commission[Object.keys(commission)]['ygb-affiliate-first-buy'] || 0} type="token" />}</span>
                                    </AffiliateCard>
                                </div>
                            </React.Fragment>
                        ))}

                    </div>
                </Inner>
                <BottomNav active="affiliates" />
            </Wrapper>
        );
    }
}

export default PanelAffiliates;