//libraries & components libraries
import React from 'react';
import styled from 'styled-components';
import I18n from '../../../../i18n';
import BottomNav from '../../../components/bottomNav/BottomNav';
import PanelNav from '../../../components/nav/PanelNav';
import OButton from '../../../styled/button';
import { Wrapper, Inner, Separator, FlexBox } from '../../../styled/wrappers';
import { AuthBox, AuthCol, AuthHeader, AuthRow, AuthSeparator, AuthTitle } from '../../auth/styled';
import { Icon } from 'semantic-ui-react';
import { MethodSeparator } from '../styled';
import { NumericFormat } from '../../../components/numericFormat/NumericFormat';

const CheckoutMessageBox = styled(AuthBox)`
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
`;

class CheckoutStep3 extends React.Component {

    constructor(props) {

        super(props)
        this.state = {
            selectedPackage: {},
            status: this.props.match.params.status
        };

    }

    componentDidMount() {

        if (window.localStorage.getItem('pk')) {

            this.setState({selectedPackage: JSON.parse(window.localStorage.getItem('pk'))});
            

        }

    }

    onAccept = () => {

        const { status } = this.state;

        if (status === 'correct') {

            window.localStorage.removeItem('pk');
            this.props.history.push(`/panel`);

        } else {

            this.props.history.push(`/checkout/methods`);

        }

    }

    render() {

        const { selectedPackage, status } = this.state;

        return (
            <Wrapper>
                <PanelNav active="" />
                <Inner>
                    <Separator xL />
                    <AuthRow>
                        <AuthCol style={{zIndex: 1}}>
                            <CheckoutMessageBox>
                                <AuthHeader>
                                    <AuthTitle>
                                        {

                                            status === 'correct' ? 
                                            <MethodSeparator>
                                                <FlexBox>
                                                    <Icon name="check circle" className="brand" />
                                                    <span>{I18n.t('payment.correctPayment')}</span>
                                                </FlexBox>
                                            </MethodSeparator> : 
                                            <MethodSeparator>
                                                <FlexBox>
                                                <Icon name="times circle" />
                                                    <span>{I18n.t('payment.inCorrectPayment')}</span>
                                                </FlexBox>
                                            </MethodSeparator>

                                        }
                                    </AuthTitle>
                                 </AuthHeader>
                                <AuthSeparator />
                                <AuthSeparator />
                                {status === 'correct' && 
                                    <>
                                        <p className="paragraph regular">{ I18n.t("payment.resumePayment") }</p>
                                        <p className="paragraph"><NumericFormat value={selectedPackage?.priceEUR} type="eur" /> - <NumericFormat value={selectedPackage?.tokens} type="token" suffix="YGB"/></p>
                                    </>
                                }
                                <AuthSeparator />
                                <OButton upper type="button" fluid color="#FFF" terciary onClick={this.onAccept}>
                                    <span>{status === 'correct' ? I18n.t('buttons.actions.goBack') : I18n.t('buttons.actions.tryAgain')}</span>
                                </OButton>
                            </CheckoutMessageBox>
                        </AuthCol>
                    </AuthRow>
                </Inner>
            <BottomNav active="" />
        </Wrapper>
            
        );

    }

}

export default CheckoutStep3;