import React, { Component } from 'react';
import { FlexBox, Inner, Separator, Wrapper } from '../../../styled/wrappers';
import { WizzardWrapper, WizzardBox, WizzardHeader, WizzardBody } from '../styled';
import CoinImage from '../../../assets/img/coin_3.png';
import OButton from '../../../styled/button';
import I18n from '../../../../i18n';
import queryString from 'query-string';
//models
import PaymentModel from '../../../../models/payments';

class EarnLanding extends Component {

    componentDidMount() {

        this.setReferrerUserId();

    }

    onStart = () => {

        let wizzard = [
            {
                userAnswer: -1
            },
            {
                userAnswer: -1
            },
            {
                userAnswer: -1
            }
        ];

        if (localStorage.getItem('wizzard')) {

            wizzard = JSON.parse(localStorage.getItem('wizzard'));
            
        }

        window.localStorage.setItem('wizzard', JSON.stringify(wizzard));
        this.props.history.push('/earn/step/1');
        
    }

    setReferrerUserId = async () =>  {

        let params = queryString.parse(this.props.location.search)

        if (params.ref) {

            localStorage.setItem('ref', params.ref);

            try {

                await PaymentModel.registerAffiliateClick({referrerUserId: params.ref});

            } catch (error) {

                console.error('App: codeError', error)

            }

        }
                   
    }

    render() {
        
        return (
            <Wrapper>
                <Inner style={{paddingTop: '5rem'}}>
                    <WizzardWrapper>
                        <WizzardBox>
                            <WizzardHeader $isImage>
                                <div>
                                    <img src={CoinImage} alt="logo ygb" />
                                </div>
                            </WizzardHeader>
                            <WizzardBody>
                                <p className="card-title" style={{margin: '0'}}>{I18n.t('earn.landing.title')}</p>
                                <p className="paragraph regular">{I18n.t('earn.landing.description')}</p>
                                <Separator />
                                <FlexBox $alignItems="center" $justifyContent="center">
                                    <OButton terciary color="#fff" type="button" upper onClick={this.onStart}>
                                        <span>{I18n.t('earn.landing.startLesson1')}</span>
                                    </OButton>
                                </FlexBox>
                            </WizzardBody>
                        </WizzardBox>
                    </WizzardWrapper>
                </Inner>
            </Wrapper>
        );
    }
}

export default EarnLanding;