//libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import I18n from '../../../../i18n';

//components
import CustomInput from '../../../components/form/input';
import Messager from '../../../components/messager/Messager';

//styled components
import { AuthBox, AuthCol, AuthRow} from '../styled';
import { AuthCTALogin, AuthHeader, AuthSeparator, AuthTitle } from '../styled';
import OButton from '../../../styled/button';

//model
import SecurityModel from '../../../../models/security/security';

class RecoveryStep1 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false,
            correct: false
        }
    }

    onRecovery = async payload => {

        try {

            const response = await SecurityModel.recoveryPassword({email: payload.email});

            if (response.status === 200) {

                this.setState({correct: true, hasError: false});
            }

        } catch (codeError) {
            
            this.setState({hasError: true, codeError: 'error-recovery'});

        }

    };

    gotoLogin = () => this.props.history.push(`/auth/login`);

    render() {

        const { codeError, hasError, correct } = this.state;
        const { valid } = this.props

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{I18n.t('recovery.recoveryYourPassword')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className="paragraph regular">{ I18n.t("recovery.recoveryMessage") }</p>
                        <AuthSeparator />
                        <Form name="recoveryStep1Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            <Messager code={codeError} active={hasError} $variant={'incorrect'}/>
                            <Messager code={'correct-recovery'} active={correct} $variant={'correct'}/>
                            <AuthSeparator>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('recovery.email') }
                                    name="email"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t('recovery.email') }
                                    restrictions={ [{ trim: true }] } />
                            </AuthSeparator>
                            <AuthSeparator />
                            <OButton upper type="submit" fluid color="#FFF" terciary disabled={!valid}>
                                <span>{I18n.t('buttons.actions.recoveryPassword')}</span>
                            </OButton>
                            <AuthSeparator />
                            <AuthCTALogin style={{textAlign: 'center'}}>
                                <span className="labeling regular brand c-pointer" onClick={this.gotoLogin}>{I18n.t('buttons.actions.cancel')}</span>
                            </AuthCTALogin>
                        </Form>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
            
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');
    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {
        errors.email = I18n.t('validations.emailInvalid');
      }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep1Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        email: '',
    }
})(connect(null, { })(RecoveryStep1));