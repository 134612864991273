import styled from 'styled-components/macro';
import { device } from '../../../styled/mbreakpoints';

const WrapperProfile = styled.div`
    display:flex;
`;

const ProfileAside = styled.div`

    flex: 0 0 215px;
    margin-right:3vw;
    display:none;
    @media ${ device.tablet } { 
        display:block;
    }
`;

const ProfileContent = styled.div`
    flex:1;
    
`;

export {
    WrapperProfile,
    ProfileAside,
    ProfileContent
}