import React, { Component } from 'react'
import Nav from '../../../../components/nav/Nav';

import { ButtonWrapper, FlexWrapper, Inner, Separator } from '../../../../styled/wrappers';
import { CheckerIconCol, CheckerRow, CheckerTextCol, LandingWrap, Section, SectionHero } from '../../styled';
import HeroImage from '../../../../assets/img/yogabot-profesional.png';
import OButton from '../../../../styled/button';
import RealityImage from '../../../../assets/img/yogabot_realidad.png'
import MarketImage from '../../../../assets/img/mercado.png'
import YogabotToken from '../../../../assets/img/yogabot_token.png';
import YogabotWallet from '../../../../assets/img/wallet.png';
import Icon1 from '../../../../assets/img/iconos/flexibilidad.png';
import Icon2 from '../../../../assets/img/iconos/personalizacion.png';
import Icon3 from '../../../../assets/img/iconos/especializacion.png';
import Icon4 from '../../../../assets/img/iconos/accesibilidad.png';
import Icon5 from '../../../../assets/img/iconos/conectividad.png';
import BgHero from '../../../../assets/img/fondo_ecosystem.jpg';
import { Icon } from 'semantic-ui-react';
import { VGrid, VItem } from './styled';
import I18n from '../../../../../i18n';
import { Footer } from '../../../../components/footer/Footer';

class EcoSystem extends Component {

    render() {

        return (
            <LandingWrap>
              <Nav {...this.props} active="ecosystem" />
                <SectionHero bg={BgHero}>
                    <Separator />
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={HeroImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-1 white">{I18n.t('landing.ecosystem.section1.title')}</p>
                                    <p className="heading-2 regular white">{I18n.t('landing.ecosystem.section1.subtitle')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.startNow')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                            
                        </div>
                    </Inner>
                    <Separator />
                </SectionHero> 
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={RealityImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.ecosystem.section2.title')}</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section2.p1')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section2.p2')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section2.p3')}}></p>
                                    <ButtonWrapper reverse>
                                        <OButton terciary upper color="#fff" size="large">
                                            <a className="white" style={{display:'block'}} href="https://yogabot.app" target="_blank" rel="noopener noreferrer" alt="yogabot">{I18n.t('buttons.actions.discoverYogabot')}</a>
                                        </OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                        <div className="row reversing">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.ecosystem.section3.title')}</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section3.p1')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section3.p2')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.ecosystem.section3.p3')}}></p>
                                    <ButtonWrapper reverse >
                                        <OButton onClick={()=> this.props.history.push('/roadmap')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.incomming')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <img src={MarketImage} alt="" />
                            </div>
                        </div>
                    </Inner>
                </Section>  
                <Section grayed>
                    <Inner>
                        <div className="row reversing">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={YogabotToken} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.ecosystem.section4.title')}</p>
                                    <Separator />
                                    <p className="paragraph regular">{I18n.t('landing.ecosystem.section4.subtitle')}</p>
                                    <Separator />
                                    <CheckerRow>
                                        <CheckerIconCol>
                                            <Icon name="check" size="small" />
                                        </CheckerIconCol>
                                        <CheckerTextCol>
                                            <span className="paragraph regular">{I18n.t('landing.ecosystem.section4.p1')}</span>
                                        </CheckerTextCol>
                                    </CheckerRow>
                                    <CheckerRow>
                                        <CheckerIconCol>
                                            <Icon name="check" size="small" />
                                        </CheckerIconCol>
                                        <CheckerTextCol>
                                            <span className="paragraph regular">{I18n.t('landing.ecosystem.section4.p2')}</span>
                                        </CheckerTextCol>
                                    </CheckerRow>
                                    <CheckerRow>
                                        <CheckerIconCol>
                                            <Icon name="check" size="small" />
                                        </CheckerIconCol>
                                        <CheckerTextCol>
                                            <span className="paragraph regular">{I18n.t('landing.ecosystem.section4.p3')}</span>
                                        </CheckerTextCol>
                                    </CheckerRow>
                                    <Separator />
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.buyYGB')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                        <div className="row reversing">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.ecosystem.section5.title')}</p>
                                    <Separator />
                                    <p className="paragraph regular">{I18n.t('landing.ecosystem.section5.p1')}</p>                                    
                                    <ButtonWrapper reverse>
                                        <OButton terciary upper color="#fff" size="large" onClick={()=> this.props.history.push('/roadmap')}>
                                            <span>{I18n.t('buttons.actions.incomming')}</span>
                                        </OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <img src={YogabotWallet} alt="" />
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <div>
                                    <p className="heading-2">{I18n.t('landing.ecosystem.section6.title')}<br/>
                                        <span className="heading-2 regular">{I18n.t('landing.ecosystem.section6.subtitle')}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Separator />
                        <Separator />
                        <VGrid>
                            <VItem>
                                <img src={Icon1} alt="" />
                                <p className="paragraph">{I18n.t('landing.ecosystem.section6.cards.c1.title')}</p>
                                <p className="paragraph regular alignCenter">{I18n.t('landing.ecosystem.section6.cards.c1.text')}</p>
                            </VItem>
                            <VItem>
                                <img src={Icon2} alt="" />
                                <p className="paragraph">{I18n.t('landing.ecosystem.section6.cards.c2.title')}</p>
                                <p className="paragraph regular alignCenter">{I18n.t('landing.ecosystem.section6.cards.c2.text')}</p>
                            </VItem>
                            <VItem>
                                <img src={Icon3} alt="" />
                                <p className="paragraph">{I18n.t('landing.ecosystem.section6.cards.c3.title')}</p>
                                <p className="paragraph regular alignCenter">{I18n.t('landing.ecosystem.section6.cards.c3.text')}</p>
                            </VItem>
                            <VItem>
                                <img src={Icon4} alt="" />
                                <p className="paragraph">{I18n.t('landing.ecosystem.section6.cards.c4.title')}</p>
                                <p className="paragraph regular alignCenter">{I18n.t('landing.ecosystem.section6.cards.c4.text')}</p>
                            </VItem>
                            <VItem>
                                <img src={Icon5} alt="" />
                                <p className="paragraph">{I18n.t('landing.ecosystem.section6.cards.c5.title')}</p>
                                <p className="paragraph regular alignCenter">{I18n.t('landing.ecosystem.section6.cards.c5.text')}</p>
                            </VItem>
                        </VGrid>
                    </Inner>
                </Section>
                <Footer />   
            </LandingWrap>
        );

    }

}

export default EcoSystem