import React, { useState, useEffect } from 'react'
import { Icon } from 'semantic-ui-react';
import I18n from '../../../i18n';
import * as S from './styled'

export default function PanelNav({active, innerNavItems = {} }) {

    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {

        activeMenu ? document.body.classList.add('disabled-scroll') : document.body.classList.remove('disabled-scroll')

        return () =>  document.body.classList.remove('disabled-scroll')
 
     }, [activeMenu]);

    return (
        <S.Nav>
            {/* SOLO MOBILE */}
            <S.Drawer $active={activeMenu}>
                <S.DropdownList $active={activeMenu}>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'profileAccount'} to="/profile/account">{I18n.t('navigation.panel.profile')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'profileSecurity'} to="/profile/security">{I18n.t('navigation.panel.password')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'profileEmail'} to="/profile/email">{I18n.t('navigation.panel.email')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'profilePreferences'} to="/profile/preferences">{I18n.t('navigation.panel.preferences')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink to="/?logout=true">{I18n.t('navigation.panel.logout')}</S.NavLink>
                    </S.DropdownItem>
                </S.DropdownList>
            </S.Drawer>
            <S.NavContent $justify="flex-end">
                {/* SOLO DESKTOP */}
                <S.NavList>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'panel'} to="/panel">{I18n.t('navigation.panel.init')}</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'transactions'} to="/panel/transactions/history">{I18n.t('navigation.panel.transactions')}</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'affiliates'} to="/panel/affiliates">{I18n.t('navigation.panel.panelAffiliates')}</S.NavLink>
                    </S.ListItem>
                </S.NavList>
                {/* SOLO DESKTOP */}
                <S.ProfileLink activeLink={active === 'profileAccount'} to="/profile/account" >
                    <Icon name="user circle"/>
                </S.ProfileLink>
                {/* SOLO MOBILE */}
                <S.InnerNav>
                    {innerNavItems?.render && innerNavItems.render()}
                </S.InnerNav>
                <S.ProfileButton onClick={() => setActiveMenu(prevActiveMenu => !prevActiveMenu)}>
                    <div>
                        {activeMenu ?  <Icon name="close"/> : <Icon name="user circle"/> }
                    </div>
                </S.ProfileButton>
            </S.NavContent>
        </S.Nav>
    )
}