import styled from 'styled-components/macro';

const VGrid = styled.div`
    display:flex;
    flex-wrap:wrap;
    width:100%;
    justify-content:center;
    align-items:flex-start;
`;

const VItem = styled.div`

    flex: 1 0 25rem;
    max-width: 26rem;
    padding:1rem 1.5rem 3rem 1.5rem;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    img {
        margin-bottom:1.5rem;
        max-width:97px;
        height:auto;
        display:block;
    }
`;

export {
    VGrid,
    VItem
}