import React, { Component } from 'react';
import { Icon, Form, Radio } from 'semantic-ui-react';
import { FlexBox, Inner, Wrapper, Separator } from '../../../styled/wrappers';
import Vimeo from '@u-wave/react-vimeo';
import { WizzardWrapper, QuestionBox, WizzardNav, WizzardBox, WizzardBody, WizzardFooter } from '../styled';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';
import I18n from '../../../../i18n';
import OButton from '../../../styled/button';
import { connect } from 'react-redux'

class EarnStep2 extends Component {

    constructor(props) {
        super(props);

        this.currentStep = 1;

        this.state = {
            correctAnswer: 1,
            userAnswer: JSON.parse(window.localStorage.getItem('wizzard'))[this.currentStep].userAnswer || -1,
            showQuestionary: JSON.parse(window.localStorage.getItem('wizzard'))[this.currentStep].userAnswer !== -1,
            showButton: false
        }
    }

    componentWillUnmount() {

        this.props.closeGenericModal();

    }

    handleChange = (e, { value }) => {

        const { correctAnswer } = this.state;
        const { openGenericModal, closeGenericModal } = this.props;

        this.setState({ userAnswer: value });
        let updatedWizzard = JSON.parse(window.localStorage.getItem('wizzard'));
        updatedWizzard[this.currentStep].userAnswer = value;
        window.localStorage.setItem('wizzard', JSON.stringify(updatedWizzard));

        const content = () => {

            return (
                <div>
                    <div className="alignCenter">
                        <Icon name="warning circle" size="huge" className="brand-alarm" />
                        <Separator />
                        <div>
                            <p className="heading-3">{I18n.t('popups.alert')}</p>
                            <p className="paragraph regular">{I18n.t('popups.missmatchAnswer')}</p>
                        </div>
                    </div>
                </div>
            )
        }

        if (value !== correctAnswer) {

            openGenericModal({
                type: 'withHtml',
                content: content,
                buttons: [
                    {
                        text: I18n.t('buttons.actions.tryAgain'),
                        callback: () => {

                            closeGenericModal();

                        },
                        options: {
                            terciary: true,
                            color: '#fff',
                            fluid: true,
                            upper: true
                        }
                    }

                ]
            });
        }

    };

    onTimeUpdate = current => {

        const { showQuestionary } = this.state;

        if (!showQuestionary && current.percent > 0.90) {

            this.setState({ showButton: true });

        }

    }

    render() {

        const { correctAnswer, userAnswer, showQuestionary, showButton } = this.state;
        const canGoToPrevious = this.currentStep > 0;
        const canGoToNext = this.currentStep < 2 && (userAnswer === correctAnswer);

        return (
            <Wrapper>

                <Inner style={{ paddingTop: '2rem' }}>
                    <WizzardWrapper>
                        <WizzardNav>
                            <div>
                                {canGoToPrevious && <Icon className="c-pointer" name="arrow left" onClick={() => this.props.history.push(`/earn/step/${this.currentStep}`)} />}
                            </div>
                            <div>
                                {canGoToNext && <p className="paragraph c-pointer" onClick={() => this.props.history.push('/earn/step/3')}>{I18n.t('buttons.actions.next')}</p>}
                            </div>
                        </WizzardNav>
                        <WizzardBox>
                            <Vimeo video={"https://player.vimeo.com/video/593433385?h=235e410ac8&color=E0684B"} responsive={true} onTimeUpdate={this.onTimeUpdate} />
                            <WizzardBody>
                                <FlexBox $alignItems="center" $spacex>
                                    <Icon name="play circle" />
                                    <span className="paragraph regular">{I18n.t('earn.lesson', { lesson: this.currentStep + 1 })}</span>
                                </FlexBox>
                                <div>
                                    <p className="card-title">{I18n.t('earn.step2.title')}</p>
                                    <p className="paragraph regular">{I18n.t('earn.step2.description')}</p>
                                </div>
                                <Separator />
                                {
                                    showButton &&
                                    <FlexBox $alignItems="center" $justifyContent="center">
                                        <OButton terciary color="#fff" type="button" upper onClick={() => this.setState({ showQuestionary: true, showButton: false })}>
                                            <span>{I18n.t('earn.startQuestionary')}</span>
                                        </OButton>
                                    </FlexBox>
                                }

                                {(showQuestionary && userAnswer !== correctAnswer) &&
                                    <>
                                        <FlexBox $alignItems="center" $spacex>
                                            <Icon name="pencil" />
                                            <span className="paragraph regular brand-primary-light">{I18n.t('earn.test')}</span>
                                        </FlexBox>
                                        <QuestionBox>
                                            <p className="paragraph" style={{ margin: '0' }}>{I18n.t('earn.step2.question')}</p>
                                            <p className="paragraph regular brand-primary-light" >{I18n.t('earn.selectAnswer')}</p>
                                            <Form>
                                                <Form.Field>
                                                    <Radio
                                                        label={I18n.t('earn.step2.answer1')}
                                                        value={1}
                                                        checked={userAnswer === 1}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Radio
                                                        label={I18n.t('earn.step2.answer2')}
                                                        value={2}
                                                        checked={userAnswer === 2}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <Radio
                                                        label={I18n.t('earn.step2.answer3')}
                                                        value={3}
                                                        checked={userAnswer === 3}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Field>
                                            </Form>
                                        </QuestionBox>
                                    </>
                                }
                            </WizzardBody>
                            {(userAnswer === correctAnswer) &&
                                <WizzardFooter>
                                    <FlexBox $alignItems="center" $spacex>
                                        <Icon name="check circle" className="brand" />
                                        <p className="paragraph">{I18n.t('earn.completed')}</p>
                                    </FlexBox>
                                    <span className="paragraph">1€ en YGB</span>
                                </WizzardFooter>
                            }
                        </WizzardBox>
                    </WizzardWrapper>
                    <Separator />
                </Inner>

            </Wrapper>
        );
    }
}

export default connect(null, { openGenericModal, closeGenericModal })(EarnStep2);