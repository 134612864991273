//libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import I18n from '../../../../i18n';

//components
import CustomInput from '../../../components/form/input';
import Messager from '../../../components/messager/Messager';

//styled components
import { AuthBox, AuthCol, AuthRow} from '../styled';
import { AuthHeader, AuthSeparator, AuthTitle } from '../../auth/styled';
import OButton from '../../../styled/button';

//model
import SecurityModel from '../../../../models/security/security';

//redux

import { closeLoader, openLoader } from '../../../../redux-store/loader';

class RecoveryStep2 extends React.Component {

    constructor(props){
        super(props);

        this.state = {
            codeError: -1,
            hasError: false
        }
    }

    onRecovery = async payload => {

        const { closeLoader, openLoader, match, history} = this.props;

        let hasError = false;

        try {

            openLoader();
            await SecurityModel.setRecoveryPassword({newPassword: payload.newPassword, token: _.get(match,'params.token', '') });

        } catch (errorCode) {

            hasError = true;
            console.error(errorCode);
            
        } finally {

            closeLoader();

            if (hasError) {

                this.setState({hasError: true, codeError: 'error-recovery'});

            } else {

                history.push(`/auth/recovery/step/3`);
                
            }

        }
    };

    gotoLogin = () => this.props.history.push(`/auth/login`);

    render() {

        const { codeError, hasError } = this.state;

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{I18n.t('recovery.restoreYourPassword')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        <p className="paragraph regular">{ I18n.t("recovery.restoreMessage") }</p>
                        <AuthSeparator />
                        <Form name="recoveryStep1Form" onSubmit={this.props.handleSubmit(this.onRecovery)}>
                            <Messager code={codeError} active={hasError} $variant={'incorrect'}/>
                            <AuthSeparator>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("recovery.newPassword") }
                                    name="verifyPassword"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t("recovery.newPassword") }
                                    type="password" />
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t("recovery.verifyPassword") }
                                    name="newPassword"
                                    fieldClasses="y-input default small"
                                    label={ I18n.t("recovery.verifyPassword") }
                                    type="password" />
                            </AuthSeparator>
                            <AuthSeparator />
                            <OButton upper type="submit" fluid color="#FFF" terciary>
                                <span>{I18n.t('buttons.actions.restorePassword')}</span>
                            </OButton>
                            <AuthSeparator />
                        </Form>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
            
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.verifyPassword)) {

        errors.verifyPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.verifyPassword) && !_.isEmpty(formValues.newPassword) && !_.isEqual(formValues.verifyPassword, formValues.newPassword) ) {

        errors.newPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};

export default reduxForm({
    form: 'recoveryStep2Form',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        verifyPassword : '',
        newPassword: ''
    }
})(connect(null, { closeLoader, openLoader })(RecoveryStep2));