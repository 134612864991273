import React, { Component } from 'react';
import _ from 'lodash';
import PanelNav from '../../../components/nav/PanelNav';
import { Inner, Separator } from '../../../styled/wrappers';
import { WrapperProfile, ProfileAside, ProfileContent } from '../styled';
import {Form} from 'semantic-ui-react';
import SecurityModel from '../../../../models/security/security';
import CustomInput from '../../../components/form/input';
import I18n from '../../../../i18n';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OButton from '../../../styled/button';
import { openToast } from '../../../../redux-store/toast';
import ProfileMenu from './menu/ProfileMenu';


class ProfileSecurity extends Component {
    
    onChangePassword = async credentials => {
        
        try {
            
            const response = await SecurityModel.changePassword({ currentPassword: credentials.currentPassword, newPassword: credentials.newPassword });

            if (response.status === 200) {

                openToast({message: I18n.t('messages.passwordChanged'), type: 'success'});

            }

        } catch (errorCode) {
            
            openToast({message: I18n.t(`status.code.${errorCode}`), type: 'error'});

        }

    }


    render() {
        return (
            <>
                <PanelNav active="profileSecurity" />
                <Inner>
                    <Separator xL />
                    <WrapperProfile>
                        <ProfileAside>
                            <ProfileMenu active="profileSecurity" />
                        </ProfileAside>
                        <ProfileContent>
                        <p className="heading-3">{I18n.t('profile.changePassword')}</p>
                        <Form name="profileSecurityForm" noValidate onSubmit={this.props.handleSubmit(this.onChangePassword)}>
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.currentPassword') }
                                name="currentPassword"
                                fieldClasses="y-input default small"
                                label={ I18n.t('profile.currentPassword') }
                                type="password" />
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.newPassword') }
                                name="newPassword"
                                fieldClasses="y-input default small"
                                label={ I18n.t('profile.newPassword') }
                                type="password" />
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('profile.confirmPassword') }
                                name="verifyPassword"
                                fieldClasses="y-input default small"
                                label={ I18n.t('profile.confirmPassword') }
                                type="password" />
                            <OButton upper type="submit" color="#FFF" terciary>
                                <span>{I18n.t('buttons.actions.saveChanges')}</span>
                            </OButton>
                        </Form>
                        </ProfileContent>
                    </WrapperProfile>
                </Inner>
            </>
        );
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.currentPassword)) {

        errors.currentPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.verifyPassword)) {

        errors.verifyPassword = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.newPassword)) {

        errors.newPassword = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.verifyPassword) && !_.isEmpty(formValues.newPassword) && !_.isEqual(formValues.verifyPassword, formValues.newPassword) ) {

        errors.verifyPassword = I18n.t('validations.passwordNotMatch');

    }

    return errors;

};


export default reduxForm({
    form: 'profileSecurityForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    enableReinitialize: true,
    initialValues: {
        currentPassword: '',
        newPassword: '',
        verifyPassword: ''
        
    }
})(connect(null, { })(ProfileSecurity));