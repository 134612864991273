import React, { Component } from 'react'
import Nav from '../../../../components/nav/Nav';

import { ButtonWrapper, FlexWrapper, Inner } from '../../../../styled/wrappers';
import { LandingWrap, Section, SectionHero } from '../../styled';
import BgHero2 from '../../../../assets/img/roadmap.png';
import OButton from '../../../../styled/button';
import { Timeline } from './components/timeline/TimeLine';
import BgHero from '../../../../assets/img/fondo_ecosystem.jpg';
import I18n from '../../../../../i18n';
import { Footer } from '../../../../components/footer/Footer';

class RoadMap extends Component {

    render() {

        return (
            <LandingWrap>
              <Nav {...this.props} active="roadmap"/>
                <SectionHero bg={BgHero} bg2={BgHero2} roadmap>
                    <Inner hero>
                        <div className="row" style={{width: '1000%'}}>
                            <div className="col-sm-12 offset-md-6 col-md-6 offset-lg-6 col-lg-6 col-xl-5 offset-xl-7">
                                <FlexWrapper justifyCenter fHeight="100%" alternative>
                                    <p className="heading-1 white">{I18n.t('landing.roadmap.section1.title')}</p>
                                    <p className="heading-2 regular white">{I18n.t('landing.roadmap.section1.subtitle')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.startNow')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </SectionHero>
                <Section grayed>
                    <Inner>
                        <Timeline />
                    </Inner>
                </Section>
                <Footer />     
            </LandingWrap>
        );

    }

}

export default RoadMap