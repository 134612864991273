import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

import BottomNav from '../../../components/bottomNav/BottomNav';
import Goback from '../../../components/goBack/GoBack';
import PanelNav from '../../../components/nav/PanelNav';
import { BackActionMobile } from '../../../components/nav/styled';
import { NumericFormat } from '../../../components/numericFormat/NumericFormat';
import PaypalButton from '../../../components/payments/paypal.js';

import { Separator, Wrapper, Inner } from '../../../styled/wrappers';

import { HeaderTotalInfo, PaymentHeader, PaymentWrapper, MethodSeparator, PaymentBody } from '../styled';

import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';

import I18n from '../../../../i18n';

import './styles.scss';

const NavItemsOptions =  {
    render: () => (
        <>
            <BackActionMobile as={Link} to={ "/checkout/packages" }>
                <Icon name="arrow left" size="large" />
            </BackActionMobile>
            <span className="paragraph white regular m-auto">{ I18n.t('buttons.actions.buy') }</span>
        </>
    )
}

class CheckoutStep2 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedPackage: {}
        };

    }

    componentDidMount() {

        //obtener del localstorage los valores del paquete seleccionado en el anterior paso
        if (window.localStorage.getItem('pk')) {

            this.setState({ selectedPackage: JSON.parse(window.localStorage.getItem('pk')) });

        } else {

            //si no hay nada en el pk mandarlo al paso anterior
           this.showPopup();

        }

    }

    componentWillUnmount() {

        // Hack para eliminar la librería Paypal porque sino falla al recargarla una vez cargada por primera vez.
        Object.keys(window).forEach(key => {

            if (/paypal|zoid|post_robot/.test(key)) {

                delete window[key];

            }

        });

        document.querySelectorAll('script[src*="www.paypal.com/sdk"]').forEach(node => node.remove());

    }

    showPopup = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('popups.alert'),
                classes: ['heading-2']
            },
            description: {
                text: I18n.t('popups.unselectedPackage'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('buttons.actions.understood'),
                callback: () => {

                    closeGenericModal();
                    this.props.history.push('/checkout/packages');

                },
                options: {
                    terciary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onCancel = data => {}

    onError = error => {

        this.props.history.push('/checkout/result/incorrect');

    }

    onSuccess = payment => {

        this.props.history.push('/checkout/result/correct');

    }

    render() {

        const { userSession } = this.props;
        const { selectedPackage } = this.state;

        return (
            <Wrapper>
                <PanelNav active="" innerNavItems={ NavItemsOptions } />
                <Inner>
                    <Goback to={ "/checkout/packages" }/>
                    <p className="paragraph alignCenter">{ I18n.t('payment.choosePaymentOption') }</p>
                    <PaymentWrapper>
                        <PaymentHeader>
                            <Icon name="payment" size="big" />
                            <HeaderTotalInfo>
                                <div className="paragraph white regular">{ I18n.t('payment.total') }</div>
                                <div className="heading-3 white"><NumericFormat value={ selectedPackage?.priceEUR } type="eur" /></div>
                            </HeaderTotalInfo>
                        </PaymentHeader>
                        <PaymentBody>
                            <MethodSeparator>
                                <p className="paragraph regular">{ I18n.t('payment.payWithPayPal') }</p>
                            </MethodSeparator>
                            <Separator />
                            <PaypalButton
                                payer={{ name: _.get(userSession, 'surname', '') + ',' + _.get(userSession,'name', ''), email_address: _.get(userSession, 'email', '') }}
                                intent={ 'CAPTURE' } // 'CAPTURE' = inmediate pay, 'AUTHORIZE' = reserve money
                                currency={ 'EUR' }
                                amount={ selectedPackage?.priceEUR }
                                tokens={ selectedPackage?.tokens }
                                onSuccess={ this.onSuccess }
                                onError={ this.onError }
                                onCancel={ this.onCancel } />
                        </PaymentBody>
                    </PaymentWrapper>
                    <Separator xL/>
                </Inner>
                <BottomNav active="" />
            </Wrapper>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {})
    };

};

export default connect(mapStateToProps, { openGenericModal, closeGenericModal })(CheckoutStep2);