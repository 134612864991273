// Types
const OPEN_LOADER = 'OPEN_LOADER';
const CLOSE_LOADER = 'CLOSE_LOADER';

// Actions
export const openLoader = () => ({ type: OPEN_LOADER, payload: { open: true } });
export const closeLoader = () => ({ type: CLOSE_LOADER, payload: { open: false } });

// Reducer
const INITIAL_STATE = { open: false };

function reducer(state = INITIAL_STATE, action) {

    switch (action.type) {

        case OPEN_LOADER:
        case CLOSE_LOADER:

            return { ...action.payload };

        default:

            return state;

    }

}

export default reducer;