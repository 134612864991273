
import React from 'react';
import _ from 'lodash';
import { FrameClose, FrameModal, ModalContent, Actions, Content } from '../../styled';
import { Icon } from 'semantic-ui-react';
import OButton from '../../../../styled/button';

/***
 * * SHARED DATA ACCEPT MODAL
 * 
 * @param buttons Array Objects
 * @param onClose Function
 * @param title String
 * @param subtitle String
 * @param description String 
 * @params @buttons { text, callback, options = { any button props } }
 * @description Modal to ask to the user to accept the biometric matrix data required for the adivsor
 */
export default function SimpleModal({ title = {}, subtitle = {}, description = {}, buttons = [], scrolling = false, onClose, ...restProps }) {

    return (
        <FrameModal>
            <FrameClose>
                <Icon name="close" size="large" onClick={ onClose }/>
            </FrameClose>
            <ModalContent>
                <Content scroll={scrolling || false}>
                    {!_.isEmpty(title) && <p className={title.classes.join(" ")} >{title.text}</p>}
                    {!_.isEmpty(subtitle) && <p className={subtitle.classes.join(" ")}>{subtitle.text}</p>}
                    {!_.isEmpty(description) && <p className={description.classes.join(" ")} dangerouslySetInnerHTML={{__html: description.text }}></p>}
                </Content>
                <Actions>
                    {
                        _.map(buttons, ({text, callback, options }, index) => (
                            <OButton key={index} type="button" {...options} onClick={ callback }><span>{text}</span></OButton>
                        ))
                    }
                </Actions>
            </ModalContent>
        </FrameModal>
    )

}
