import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'semantic-ui-react';
import { closeGenericModal } from '../../../redux-store/genericModal';
import SimpleModal from './components/simple';
import WithHtmlModal from './components/withHtml';

function HandleModal({ message, onClose }) {

    switch (message.type) {

        case 'simple':

            return <SimpleModal { ...message } onClose={ onClose } />;

        case 'withHtml':

            return <WithHtmlModal { ...message } onClose={ onClose } />;

        default:

            return '';

    }
    
}

class GenericModal extends React.Component {

    render() {

        return this.props.open && (
            <Modal open={ this.props.open } size={ this.props.message.size || 'mini' }>
                <HandleModal { ...this.props } onClose={ this.props.closeGenericModal } />
            </Modal>
        );

    }

}

const mapStateToProps = state => _.cloneDeep(state.genericModal);

export default connect(mapStateToProps, { closeGenericModal })(GenericModal);