import { Button } from 'semantic-ui-react';
import styled, { css } from 'styled-components/macro';
import React from 'react';

const OButton = styled(({ color, primary, secondary, terciary, selected, upper, ...rest }) => <Button { ...rest } />)`
    &&& {
        font-family: PoppinsRegular;
        font-weight:400;
        color: var(--primary-color);
        position:relative;
        color: ${({color}) => color || '#30425a'};
        ${ ({ primary }) => primary &&
            css`
                background-color: #fff;
        ` };

        ${ ({ secondary }) => secondary &&
            css`
                background-color: var(--primary-color);
                color:#fff;
                &:after{
                    content: "";
                    border: 2px solid #fff;
                    position: absolute;
                    pointer-events: none;
                    box-sizing: border-box;
                    width: 100%;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    border-radius: inherit;

                }
        ` };

        ${({terciary}) => terciary &&
            css`
                background-color: #1fd5b9;
                &:hover {
                    background-color:#1bb89f;
                }
                &:active, &:focus {
                    color:#fff;
                    background-color:#1bb89f;
                    i {
                        &:before {
                            color:${({color}) => color || '#fff'};
                        }
                    }
                } 
               
        `};
        
        ${({selected}) => selected &&
            css`
                background-color: #fff;
                i {
                    background-color: transparent!important;
                }
                &:before {
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    border: solid 2px ${({color}) => color || '#30425a'};
                    border-radius: inherit;
                    background: transparent;
                    pointer-events: none;
                    box-sizing: border-box;
                    position:absolute;
                }
          `};

        ${ ({ upper }) => upper &&
            css`
                text-transform:upperCase;
        ` };
    }
`;

export default OButton;