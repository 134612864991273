import React from 'react'
import { TimeLineItem, TimeLineItemDate, TimeLineItemPoints, TimeLineRow, TimeLineWrapper } from './styled'
import { CheckerRow, CheckerIconCol, CheckerTextCol } from '../../../../styled'
import { Icon } from 'semantic-ui-react'
import I18n from '../../../../../../../i18n'
import { Trans } from 'react-i18next';

export function Timeline(props) {
    
    return (
        <>
            <TimeLineWrapper>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item1.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item1.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item1.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">{I18n.t('landing.roadmap.timeline.item1.milestone')}</span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow>
                    <TimeLineItem>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item2.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item2.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item2.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item2.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item3.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item3.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item3.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item3.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item3.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow> 
                <TimeLineRow>
                    <TimeLineItem>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item4.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item4.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item4.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item4.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item4.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item5.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item5.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item5.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item5.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow >
                    <TimeLineItem >
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item6.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item6.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item6.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item6.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item6.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item6.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item7.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item7.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item7.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item7.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item7.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item7.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow >
                    <TimeLineItem >
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item8.p6')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item8.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item9.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item9.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item9.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item9.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item9.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item9.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow >
                    <TimeLineItem >
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item10.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item10.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item10.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item10.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item10.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item10.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
                <TimeLineRow $start>
                    <TimeLineItem $start>
                        <TimeLineItemPoints>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item11.p1')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item11.p2')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item11.p3')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item11.p4')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                            <CheckerRow>
                                <CheckerIconCol>
                                    <Icon name="check" size="small" />
                                </CheckerIconCol>
                                <CheckerTextCol>
                                    <span className="paragraph regular">{I18n.t('landing.roadmap.timeline.item11.p5')}</span>
                                </CheckerTextCol>
                            </CheckerRow>
                        </TimeLineItemPoints>
                        <TimeLineItemDate>
                            <span className="paragraph white">
                                <Trans i18nKey="landing.roadmap.timeline.item11.milestone">
                                    <br /><span className="caption white"></span>
                                </Trans>
                            </span>
                        </TimeLineItemDate>
                    </TimeLineItem>
                </TimeLineRow>
            </TimeLineWrapper>
        </>
    ) 
}