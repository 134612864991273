import styled, {css} from 'styled-components/macro';
import { device } from '../../../../../../../styled/mbreakpoints';

const borderRightEdge = css`
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

const borderLeftEdge = css`
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`;

const TimeLineWrapper = styled.div`
    width:100%;
    position:relative;
    &:after {
        content:"";
        position:absolute;
        left:0;
        width:2px;
        background-color: var(--brand-primary);
        top:0;
        height: 100%;
    }
    @media ${ device.laptop } { 
        &:after {
            left:50%;
        }
    }
`;

const TimeLineRow = styled.div`
    display:flex;
    justify-content: flex-end;
    @media ${ device.laptop } { 
        justify-content:${({$start}) => $start ? 'flex-start' : 'flex-end'};
    }
    
    margin-bottom:1rem;
`;

const TimeLineItemDate = styled.div`
    background-color:var(--brand-primary);
    display:flex;
    align-items:center;
    justify-content:center;
    padding:2rem 1rem;
    flex: 0 0 148px;
    span {
        margin:0;
    }
    overflow: hidden;
`;

const TimeLineItemPoints = styled.div`
    background-color:#fff;
    padding:2rem 2rem;
    flex: 1;
    
`;

const TimeLineItem = styled.div`
    flex-grow:0;
    flex-shrink:0;
    display:flex;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    position:relative;
    border-radius: 1rem;
    &::after{
        content:"";
        position:absolute;
        left:0;
        width:1.5rem;
        height:1.5rem;
        border:solid 1px var(--brand-primary);
        top: calc(50% - 1.25rem);
        border-radius: 50%;
        background-color: var(--brand-primary);
        box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 1), 0px 0px 0px 6px var(--brand-primary);
    }

    &::after{
        left: calc(-1 * (3.1rem + 1px));
    }

    ${TimeLineItemDate} {
        ${borderRightEdge};
    }
    ${TimeLineItemPoints} {
        ${borderLeftEdge};
    }

    @media (max-width:768px) { 

        flex-direction: column-reverse;

        ${TimeLineItemDate} {
            flex:auto;
            border-top-right-radius: 1rem;
            border-top-left-radius: 1rem;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
        ${TimeLineItemPoints} {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
            border-bottom-right-radius: 1rem;
            border-bottom-left-radius: 1rem;
        }

    }

    @media ${ device.laptop } { 

        ${({$start}) => $start ? css`
            flex-direction:row;
            &::after{
                right: calc(-1 * (3.25rem + 1px));
                left:auto;
            }
            ${TimeLineItemDate} {
                ${borderRightEdge};
            }
            ${TimeLineItemPoints} {
                ${borderLeftEdge};
            }

        ` : css`
            flex-direction:row-reverse;
            &::after{
                left: calc(-1 * (3.25rem - 1px));
                right:auto;

            }

            ${TimeLineItemDate} {
                ${borderLeftEdge};
            }
            ${TimeLineItemPoints} {
                ${borderRightEdge};
            }
            
        `};

    }   
    
    width: calc(100% - 2.5rem);
    @media ${ device.laptop } { 
        width: calc(50% - 2.5rem);
    }
    
`;

export {
    TimeLineWrapper,
    TimeLineRow,
    TimeLineItem,
    TimeLineItemDate,
    TimeLineItemPoints
}