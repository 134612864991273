import styled from 'styled-components/macro';

const GoBack = styled.div`
    display:none;
    justify-content:flex-start;
    align-items:center;
    padding: 2rem 0 ;
    span {
        margin-left:1rem;
    }
    i {
        &:before{
            color:var(--brand-primary);
        }
    }
    @media(min-width:768px) {
        display:flex;
    }   
`;

export {
    GoBack
}