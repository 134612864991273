import React, { Component } from 'react';

import SecurityModel from '../../../../models/security/security';

//styled components
import { AuthBox, AuthCol, AuthRow} from '../styled';
import { AuthHeader, AuthSeparator, AuthTitle } from '../../auth/styled';
import I18n from '../../../../i18n';
import OButton from '../../../styled/button';

class VerifyEmail extends Component {

    constructor(props){
        super(props);

        this.state =  {
            validated: false,
            loaded: false,
            emailAlreadyInUse: false
        }
    }

    async componentDidMount() {

        try {

            const response = await SecurityModel.verifyEmail(this.props.match.params.token);

            if (response.status === 200) {

                this.setState({ validated: true, loaded: true });

            }
            
        } catch (errorCode) {

            this.setState({ validated: false, loaded: true, emailAlreadyInUse: errorCode === 409 });

        } 

    }

    render() {

        const { loaded, validated, emailAlreadyInUse } = this.state;

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle>
                                {loaded && validated && <span style={{lineHeight: '1.3'}}>{ I18n.t('verifyEmail.verifyEmailCorrect')}</span>}
                                {loaded && !validated && <span style={{lineHeight: '1.3'}}>{ I18n.t('verifyEmail.verifyEmailError')}</span>}
                            </AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <AuthSeparator />
                        {loaded && validated && <p className="paragraph regular">{ I18n.t('verifyEmail.restoreCorrectMessageSecond')}</p>}
                        {loaded && !validated && !emailAlreadyInUse && <p className="paragraph regular">{ I18n.t('verifyEmail.verifyEmailErrorTryAgain')}</p>}
                        {loaded && !validated && emailAlreadyInUse && <p className="paragraph regular">{ I18n.t('status.code.409')}</p>}
                        <AuthSeparator />
                        <OButton upper type="button" fluid color="#FFF" terciary onClick={()=> this.props.history.push(`/auth/login`)}>
                            <span>{I18n.t('buttons.actions.finalize')}</span>
                        </OButton>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
        );
    }
}

export default VerifyEmail;