import styled, {css} from 'styled-components/macro';
import bgImage from '../../../assets/img/fondo_ecosystem.jpg';

const WizzardWrapper = styled.div`
    max-width: 50rem;
    margin: 0 auto;
    padding-top:2.5rem;
    position:relative;
`;

const WizzardNav = styled.div`
    display:flex;
    position:absolute;
    align-items:center;
    justify-content:space-between;
    top:0;
    left:0;
    right:0;
    i {
        width:auto;
        height:auto;
        margin:0;
        &:before {
            color:var(--brand-primary);
        }
    }
`;

const WizzardBox = styled.div`
    border-radius:4px;
    background-color:#fff;
    
    overflow:hidden;
    box-shadow:0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid var(--brand-secondary);
`;

const WizzardHeader = styled.div`
    padding-top:56.25%;
    
    overflow:hidden;
    position:relative;
    width:100%;
    & > div {
        position:absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;

    }

    ${({$isImage}) => $isImage && css`
        
        background-image:url(${bgImage});
        background-size:cover;
        background-position:center center;

        & {
            div {
                display:flex;
                align-items:center;
                justify-content:center;
                min-width:0;
                img {
                    width:100%;
                    max-width:15rem;
                    height:auto;
                    display:block;
                }

            }
            
        }
       
    `}
`;

const WizzardBody = styled.div`
    padding:1.5rem 1.125rem;
    i {
        width:auto;
        height:auto;
        margin:0;
        &:before {
            color:var(--brand-primary);
        }
    }
`;

const WizzardFooter = styled.div`

    background:var(--light-grey-1);
    border-radius:4px;
    display:flex;
    align-items:center;
    justify-content:space-between;
    padding:1.2rem 1rem;
    margin: 1rem;
    i {
        width:auto;
        height:auto;
        margin:0;
        
    }
    && {
        p,span {
            color:var(--brand-primary-light);
        }
    }
`;

const QuestionBox = styled.div`

    padding: 0 1rem;
    
   
`;



export {
    WizzardBox,
    WizzardHeader,
    WizzardBody,
    WizzardFooter,
    WizzardWrapper,
    WizzardNav,
    QuestionBox
}