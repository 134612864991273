import React, { useState, useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react';
import I18n from '../../../i18n';
import OButton from '../../styled/button'
import { Separator } from '../../styled/wrappers';
import IconYGB from '../../assets/img/coin_3.png';
import * as S from './styled';

const countryOptions = [
    { key: 'es', value: 'es', flag: 'es', text: 'ES' },
    { key: 'gb', value: 'en', flag: 'gb', text: 'EN' }
  ]

export default function Nav({active, history}) {

    const [activeMenu, setActiveMenu] = useState(false);
    const [toggleInfo, setToggleInfo] = useState(false);
    const [toggleLang, setToggleLang] = useState(false);

    useEffect(() => {

        activeMenu ? document.body.classList.add('disabled-scroll') : document.body.classList.remove('disabled-scroll')

        return () =>  document.body.classList.remove('disabled-scroll')
 
     }, [activeMenu]);

    const switchLang = (value) => I18n.changeLanguage(value, () => window.location.reload());

    return (
        <S.Nav $active={activeMenu}>
            <S.Drawer $active={activeMenu}>
                <S.DropdownList>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'init'} to="/">{I18n.t('navigation.landing.init')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.ButtonAsLink activeLink={toggleInfo} onClick={() => setToggleInfo(prev => !prev)}>{I18n.t('navigation.landing.info')}</S.ButtonAsLink>
                        <S.InfoContentList $show={toggleInfo}>
                            <S.DropdownItem $inner>
                                <S.NavLink activeLink={active === 'ecosystem'} to="/ecosystem">{I18n.t('navigation.landing.ecosystem')}</S.NavLink>
                            </S.DropdownItem>
                            <S.DropdownItem $inner>
                                <S.NavLink activeLink={active === 'pedagogy'} to="/pedagogy">{I18n.t('navigation.landing.pedagogy')}</S.NavLink>
                            </S.DropdownItem>
                        </S.InfoContentList>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.ButtonAsLink activeLink={toggleLang} onClick={() => setToggleLang(prev => !prev)}><span>{I18n.t('navigation.landing.lang')}</span></S.ButtonAsLink>
                        <S.InfoContentList $show={toggleLang}>
                            <S.DropdownItem $inner>
                                <S.ButtonAsLink activeLink={I18n.languages[0] === 'es'} onClick={() => switchLang('es')}><i className="es flag"></i><span>{I18n.t('navigation.landing.languages.es')}</span></S.ButtonAsLink>
                            </S.DropdownItem>
                            <S.DropdownItem $inner>
                                <S.ButtonAsLink activeLink={I18n.languages[0] === 'en'} onClick={() => switchLang('en')}><i className="gb flag"></i><span>{I18n.t('navigation.landing.languages.en')}</span></S.ButtonAsLink>
                            </S.DropdownItem>
                        </S.InfoContentList>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'tokenomics'} to="/tokenomics">{I18n.t('navigation.landing.tokenomics')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'roadmap'} to="/roadmap">{I18n.t('navigation.landing.roadmap')}</S.NavLink>
                    </S.DropdownItem>
                    <S.DropdownItem>
                        <S.NavLink activeLink={active === 'affiliation'} to="/affiliation">{I18n.t('navigation.landing.affiliation')}</S.NavLink>
                    </S.DropdownItem>
                </S.DropdownList>
                <Separator />
                <div>
                    <OButton primary type="button" fluid onClick={()=> history.push('/auth/login')}>
                        <span>{I18n.t('navigation.landing.login')}</span>
                    </OButton>
                </div>
                <Separator />
                <div>
                    <OButton secondary type="button" fluid onClick={()=> history.push('/auth/register')}>
                        <span>{I18n.t('navigation.landing.join')}</span>
                    </OButton>
                </div>
            </S.Drawer>
            <S.NavContent>
                <S.NavList>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'init'} to="/">{I18n.t('navigation.landing.init')}</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.ButtonAsLink activeLink={toggleInfo} onClick={() => setToggleInfo(prev => !prev)}>{I18n.t('navigation.landing.info')}</S.ButtonAsLink>
                        <S.SubMenuModal $show={toggleInfo}>
                            <S.InfoContentList $submenu>
                                <S.DropdownItem >
                                    <S.NavLink className="submenu" activeLink={active === 'ecosystem'} to="/ecosystem">{I18n.t('navigation.landing.ecosystem')}</S.NavLink>
                                </S.DropdownItem>
                                <S.DropdownItem >
                                    <S.NavLink className="submenu" activeLink={active === 'pedagogy'} to="/pedagogy">{I18n.t('navigation.landing.pedagogy')}</S.NavLink>
                                </S.DropdownItem>
                            </S.InfoContentList>
                        </S.SubMenuModal>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'tokenomics'} to="/tokenomics">{I18n.t('navigation.landing.tokenomics')}</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'roadmap'} to="/roadmap">{I18n.t('navigation.landing.roadmap')}</S.NavLink>
                    </S.ListItem>
                    <S.ListItem>
                        <S.NavLink activeLink={active === 'affiliation'} to="/affiliation">{I18n.t('navigation.landing.affiliation')}</S.NavLink>
                    </S.ListItem>
                </S.NavList>
                <S.NavButtons>
                    <OButton primary type="button" onClick={()=> history.push('/auth/login')}>
                        <span>{I18n.t('navigation.landing.login')}</span>
                    </OButton>
                    <OButton secondary type="button" onClick={()=> history.push('/auth/register')}>
                        <span>{I18n.t('navigation.landing.join')}</span>
                    </OButton>
                </S.NavButtons>
                <S.NavLang>
                    <Dropdown placeholder='' options={countryOptions} value={I18n.languages[0]} pointing="top right" onChange={(e,{value}) => switchLang(value)}/>
                </S.NavLang>
                <S.IconMobile>
                    <img src={IconYGB} alt="icon yogabot.io" />
                </S.IconMobile>
                <S.ToogleButton onClick={() => setActiveMenu(prevActiveMenu => !prevActiveMenu)}>
                    <S.ToogleButtonBar ariaHidden="true" className="bar-1"></S.ToogleButtonBar>
                    <S.ToogleButtonBar ariaHidden="true" className="bar-2"></S.ToogleButtonBar>
                    <S.ToogleButtonBar ariaHidden="true" className="bar-3"></S.ToogleButtonBar>
                </S.ToogleButton>
            </S.NavContent>
        </S.Nav>
    )
}