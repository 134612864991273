// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

// Components
import Nav from '../../components/nav/Nav';
import { Footer } from '../../components/footer/Footer';
import { NumericFormat } from '../../components/numericFormat/NumericFormat';
import CookiesBox from '../../components/cookiesBox/CookiesBox';

// Styled components
import { CardElements, PhaseInfo, PhaseInfoProgress, PhaseInfoProgressWrapper, PhaseInfoProgressInner, Section, SectionHero, CardStaff, CardStaffContent, CardStaffAvatar, LandingWrap, CardIndex, PhaseMilestones } from './styled';
import { ButtonsWrapper, ButtonWrapper, FlexWrapper, Inner, Separator } from '../../styled/wrappers';
import OButton from '../../styled/button';

// Models
import PaymentModel from '../../../models/payments';

// Redux
import { closeSession } from '../../../redux-store/auth';

// Locales
import I18n from '../../../i18n'

// Assets
import ContextImage from '../../assets/img/context.png';
import GraphDemocratic from '../../assets/img/grafica-democratica.png';
import GraphFlow from '../../assets/img/grafico_flujo.png';
import RoadmapFlow from '../../assets/img/roadmap_flow.png';
import Jordys from '../../assets/img/jordys-gonzalez-300x300.jpg'
import Jose from '../../assets/img/Jose-Antoni-Cao-300x300.jpg'
import Gustavo from '../../assets/img/gustavo.jpeg'
import Icon1 from '../../assets/img/iconos/financiera.png';
import Icon2 from '../../assets/img/iconos/circulacion.png';
import BgHero from '../../assets/img/fondo_inicio.jpg';
import BgHero2 from '../../assets/img/usability.png';
import Coin from '../../assets/img/coin_2.png';
import StackCoin from '../../assets/img/stack_coin.png';

class Landing extends Component {

    constructor(props) {

        super(props);

        this.state = {
            stagesInfo: {}
        };

    }

    componentDidMount() {

        this.getStagesInfo();
        this.HandleLogout();

    }

    getStagesInfo = async () => {

        try {

            const response = await PaymentModel.getStagesInfo();

            this.setState({ stagesInfo: response.data });

        } catch (error) {

            console.error('error', error)

        }

    }

    HandleLogout = () => {

        const { closeSession } = this.props;

        if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = queryString.parse(UrlQueryStrings);

            if (queryValues.logout) {

                closeSession();

            }

        }

    }

    render() {

        const { stagesInfo: { stage = 0, round = 0, phase = 0, price = 0, next = {}, tokenAmount = 1,  stageTotalTokensSold = 0, totalTokensSold = 0 } } = this.state;
        const progress = Math.min(Math.round(stageTotalTokensSold/tokenAmount * 100), 100);

        return (
            <LandingWrap>
              <Nav { ...this.props } active="init" />
                <SectionHero bg={ BgHero } bg2={ BgHero2 }>
                    <Separator />
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <p className="heading-1 white" style={{ maxWidth: '40rem' }}>{ I18n.t('landing.main.section1.title') }</p>
                                <p className="heading-3 regular white" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section1.subtitle') }}></p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <PhaseInfo>
                                    <p className="heading-2" style={{ marginBottom: 0 }}>{ I18n.t('landing.main.section1.phaseInfo.p1', {stage, round, phase}) }</p>
                                    <p className="heading-3 regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section1.phaseInfo.p2', { price }) }}></p>
                                    <PhaseInfoProgress>
                                        <PhaseInfoProgressWrapper>
                                            <PhaseInfoProgressInner $progress={ progress } />
                                        </PhaseInfoProgressWrapper>
                                    </PhaseInfoProgress>
                                    <PhaseMilestones>
                                        <span className="labeling regular">0</span>
                                        <span className="labeling regular"><NumericFormat value={ tokenAmount } type="token" /></span>
                                    </PhaseMilestones>
                                    <Separator />
                                    <p className="paragraph regular">{ I18n.t('landing.main.section1.phaseInfo.p4') } <strong><NumericFormat value={ stageTotalTokensSold } type="token" /></strong></p>
                                    <p className="paragraph regular">{ I18n.t('landing.main.section1.phaseInfo.p3') } <strong><NumericFormat value={ totalTokensSold } type="token" /></strong></p>
                                    { next?.phase > 0 &&
                                        <p className="paragraph regular" style={{ marginBottom: 0 }} dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section1.phaseInfo.p5', { phase: next.phase, price: next.price }) }}></p>
                                    }
                                    <ButtonWrapper reverse>
                                        <OButton onClick={ ()=> this.props.history.push('/auth/register') } terciary upper color="#fff" size="large"><span>{ I18n.t('buttons.actions.startNow') }</span></OButton>
                                    </ButtonWrapper>
                                </PhaseInfo>
                            </div>
                        </div>
                    </Inner>
                    <Separator />
                </SectionHero>
                <Section>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <img src={ Coin } alt="Coin YGB" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <p className="heading-2">{ I18n.t('landing.main.section2.title') }</p>
                                <p className="paragraph regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section2.subtitle') }}></p>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                <p className="heading-2">{ I18n.t('landing.main.section3.title') }</p>
                                <p className="heading-2 regular">{ I18n.t('landing.main.section3.subtitle') }</p>
                                <p className="paragraph regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section3.p1') }}></p>
                                <p className="paragraph regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section3.p2') }}></p>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <CardElements>
                                    <CardIndex>1</CardIndex>
                                    <p className="card-title">{ I18n.t('landing.main.section3.cards.c1.title') }</p>
                                    <p className="paragraph regular">{ I18n.t('landing.main.section3.cards.c1.text') }</p>
                                </CardElements>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <CardElements>
                                    <CardIndex>2</CardIndex>
                                    <p className="card-title">{ I18n.t('landing.main.section3.cards.c2.title') }</p>
                                    <p className="paragraph regular">{ I18n.t('landing.main.section3.cards.c2.text') }</p>
                                </CardElements>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <CardElements>
                                    <CardIndex>3</CardIndex>
                                    <p className="card-title">{ I18n.t('landing.main.section3.cards.c3.title') }</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section3.cards.c3.text') }}></p>
                                </CardElements>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                <CardElements>
                                    <CardIndex>4</CardIndex>
                                    <p className="card-title">{ I18n.t('landing.main.section3.cards.c4.title') }</p>
                                    <p className="paragraph regular">{ I18n.t('landing.main.section3.cards.c4.text') }</p>
                                </CardElements>
                            </div>
                        </div>
                        <Section>
                            <ButtonsWrapper>
                                <OButton terciary color="#fff" size="large" upper onClick={ ()=> this.props.history.push('/ecosystem') }>
                                    <span>{ I18n.t('buttons.actions.discoverProject') }</span>
                                </OButton>
                            </ButtonsWrapper>
                        </Section>
                    </Inner>
                </Section>
                <Section grayed alternative>
                    <Separator />
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section4.title') }</p>
                                <Separator />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={ StackCoin } alt="StackCoin" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%" >
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section4.p1') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section4.p2') }}></p>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                    <Separator />
                </Section>
                <Section>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section5.title') }</p>
                                <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section5.subtitle') }</p>
                            </div>
                        </div>
                        <Separator />
                        <Separator />
                        <div className="row">
                            <div className="col-sd-12 col-md-5 col-lg-5 col-xl-5">
                                <img src={ Icon1 } alt="" style={{ width: '97px', height: '97px' }} />
                                <Separator />
                                <p className="paragraph regular alignCenter" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section5.p1') }}></p>
                            </div>
                            <div className=" offset-md-2 offset-lg-2 offset-xl-2 col-sd-12 col-md-5 col-lg-5 col-xl-5">
                                <img src={ Icon2 } alt="" style={{ width:'97px', height:'97px' }} />
                                <Separator />
                                <p className="paragraph regular alignCenter" dangerouslySetInnerHTML={{ __html: I18n.t('landing.main.section5.p2') }}></p>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                    <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section6.title') }</p>
                            </div>
                        </div>
                        <Separator />
                        <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={ GraphDemocratic } alt="GraphDemocratic" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section6.p1') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section6.p2') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section6.p3') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section6.p4') }}></p>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section7.title') }</p>
                                <p className="heading-2 regular alignCenter">{ I18n.t('landing.main.section7.subtitle') }</p>
                                <Separator />
                            </div>
                        </div>
                        <div className="row reversing">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section7.p1') }}></p>
                                    <p className="paragraph regular"dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section7.p2') }}></p>
                                </FlexWrapper>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <img src={ ContextImage } alt="Stack Coin YGB" />
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-2 regular alignCenter">{ I18n.t('landing.main.section7.subtitle2') }</p>
                                <Separator />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={ GraphFlow } alt="GraphDemocratic" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section7.p3') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section7.p4') }}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section7.p5') }}></p>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section8.title') }</p>
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardElements>
                                    <p className="card-title">{ I18n.t('landing.main.section8.cards.c1.title') }</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section8.cards.c1.text') }}></p>
                                </CardElements>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardElements>
                                    <p className="card-title">{ I18n.t('landing.main.section8.cards.c2.title') }</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section8.cards.c2.text') }}></p>
                                </CardElements>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardElements>
                                    <p className="card-title">{ I18n.t('landing.main.section8.cards.c3.title') }</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{ __html:I18n.t('landing.main.section8.cards.c3.text') }}></p>
                                </CardElements>
                            </div>
                        </div>
                        <Section>
                            <ButtonsWrapper>
                                <OButton terciary color="#fff" size="large" upper onClick={ ()=> this.props.history.push('/auth/register') }>
                                    <span>{ I18n.t('buttons.actions.buyYGB') }</span>
                                </OButton>
                            </ButtonsWrapper>
                        </Section>
                    </Inner>
                </Section>

                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section9.title') }</p>
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={ RoadmapFlow } alt="roadmap" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="paragraph regular">{ I18n.t('landing.main.section9.p1') }</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={ ()=> this.props.history.push('/roadmap') } terciary upper color="#fff" size="large">
                                            <span>{ I18n.t('buttons.actions.seeRoadmap') }</span>
                                        </OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-1 alignCenter">{ I18n.t('landing.main.section10.title') }</p>
                            </div>
                        </div>
                        <Separator />
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardStaff>
                                    <CardStaffAvatar>
                                        <div>
                                            <img src={ Gustavo } alt="gustavo hernández" />
                                        </div>
                                    </CardStaffAvatar>
                                    <CardStaffContent>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c1.name') }</p>
                                        <p className="paragraph regular brand alignCenter">{ I18n.t('landing.main.section10.cards.c1.position') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c1.position2') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c1.skills') }</p>
                                        <p className="paragraph alignCenter">{ I18n.t('landing.main.section10.cards.c1.function') }</p>
                                    </CardStaffContent>
                                </CardStaff>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardStaff>
                                    <CardStaffAvatar>
                                        <div>
                                            <img src={Jordys} alt="Jordys gonzalez" />
                                        </div>
                                    </CardStaffAvatar>
                                    <CardStaffContent>
                                    <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c2.name') }</p>
                                        <p className="paragraph regular brand alignCenter">{ I18n.t('landing.main.section10.cards.c2.position') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c2.position2') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c2.skills') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c2.function') }</p>
                                        <p className="paragraph alignCenter">{ I18n.t('landing.main.section10.cards.c2.function2') }</p>
                                    </CardStaffContent>
                                </CardStaff>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-4">
                                <CardStaff>
                                    <CardStaffAvatar>
                                        <div>
                                            <img src={Jose} alt="jose antonio cao" />
                                        </div>
                                    </CardStaffAvatar>
                                    <CardStaffContent>
                                    <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c3.name') }</p>
                                        <p className="paragraph regular brand alignCenter">{ I18n.t('landing.main.section10.cards.c3.position') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c3.position2') }</p>
                                        <p className="paragraph regular alignCenter">{ I18n.t('landing.main.section10.cards.c3.skills') }</p>
                                        <p className="paragraph alignCenter">{ I18n.t('landing.main.section10.cards.c3.function') }</p>
                                    </CardStaffContent>
                                </CardStaff>
                            </div>
                        </div>
                        <Separator />
                    </Inner>
                </Section>
                <Footer />
                <CookiesBox />
            </LandingWrap>
        );

    }

}

export default connect(null, { closeSession })(Landing)