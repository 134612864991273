// libraries & components libraries
import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import I18n from '../i18n';
import { ToastContainer } from 'react-toastify';

// components
import Landing from './routes/landing';
import Loader from './components/loader';
import GenericModal from './components/genericModal';

//routes
import EcoSystem from './routes/landing/components/ecosystem/EcoSystem';
import Tokenomics from './routes/landing/components/tokenomics/Tokenomics';
import RoadMap from './routes/landing/components/roadmap/RoadMap';
import Pedagogy from './routes/landing/components/pedagogy/Pedagogy';
import Affiliation from './routes/landing/components/affiliation/Affiliation';
import Login from './routes/auth/login/Login';
import Register from './routes/auth/register/Register';
import RecoveryStep1 from './routes/auth/recovery/RecoveryStep1';
import RecoveryStep2 from './routes/auth/recovery/RecoveryStep2';
import RecoveryStep3 from './routes/auth/recovery/RecoveryStep3';
import Panel from './routes/panel/Panel';
import TransactionHistory from './routes/transactionHistory/TransactionHistory';
import PanelAffiliates from './routes/affiliates/PanelAffiliates';
import CheckoutStep1 from './routes/checkout/step1/CheckoutStep1';
import CheckoutStep2 from './routes/checkout/step2/CheckoutStep2';
import Profile from './routes/profile/Profile';
import VerifyEmail from './routes/auth/recovery/VerifyEmail';

// global styles
import GlobalStyle from './styled/global';

//redux
import { openLoader, closeLoader } from '../redux-store/loader';
import { getProfile } from '../redux-store/auth';
import CheckoutStep3 from './routes/checkout/step3/CheckoutStep3';
import LegalCookies from './routes/legal/Cookies/LegalCookies';
import EarnLanding from './routes/earn/landing/EarnLanding';
import EarnStep1 from './routes/earn/step1/EarnStep1';
import EarnStep2 from './routes/earn/step2/EarnStep2';
import EarnStep3 from './routes/earn/step3/EarnStep3';

const PrivateRoute = ({ ...props }) => window.localStorage.getItem('jwt') ? <Route { ...props } /> : <Redirect to="/auth/login" />;

class App extends Component {

    constructor(props) {

        super(props);
        this.state = {

            loaded: false

        }

    }

    componentDidMount() {

        this.initialize();

    }

    initialize = async () => {

        const { openLoader, closeLoader, getProfile } = this.props;

        try {

            openLoader();

            const exp = window.localStorage.getItem('jwt') ? JSON.parse(atob(window.localStorage.getItem('jwt').split('.')[1])).exp : 0;

            if (Moment(exp * 1000).diff(Moment(), 'minutes') > 5) {

                await getProfile();

            } else {

                window.localStorage.removeItem('jwt');

            }

        } catch (codeError) {

            console.error('App: codeError', codeError)

        } finally {

            this.setState({ loaded: true });
            closeLoader();

        }

    }

    render() {

        const { loaded } = this.state;

        return loaded && (
            <I18nextProvider i18n={ I18n }>
                <HashRouter>
                    <Switch>
                        <PrivateRoute path="/checkout/packages" exact component={ CheckoutStep1 } />
                        <PrivateRoute path="/checkout/methods" exact component={ CheckoutStep2 } />
                        <PrivateRoute path="/checkout/result/:status" exact component={ CheckoutStep3 } />
                        <PrivateRoute path="/panel" exact component={ Panel } />
                        <PrivateRoute path="/panel/affiliates" exact component={ PanelAffiliates } />
                        <PrivateRoute path="/panel/transactions/history" exact component={ TransactionHistory } />
                        <PrivateRoute path="/profile" component={ Profile } />
                        <Route path="/legal/cookies" exact component={ LegalCookies } />
                        <Route path="/auth/recovery/step/1" exact component={ RecoveryStep1 } />
                        <Route path="/auth/recovery/step/2/:token" exact component={ RecoveryStep2 } />
                        <Route path="/auth/recovery/step/3" exact component={ RecoveryStep3 } />
                        <Route path="/auth/verifyEmail/:token" exact component={ VerifyEmail } />
                        <Route path="/auth/register" exact component={ Register } />
                        <Route path="/auth/login" exact component={ Login } />
                        <Route path="/affiliation" exact component={ Affiliation } />
                        <Route path="/pedagogy" exact component={ Pedagogy } />
                        <Route path="/roadmap" exact component={ RoadMap } />
                        <Route path="/tokenomics" exact component={ Tokenomics } />
                        <Route path="/ecosystem" exact component={ EcoSystem } />
                        <Route path="/earn" exact component={ EarnLanding } />
                        <Route path="/earn/step/1" exact component={ EarnStep1 } />
                        <Route path="/earn/step/2" exact component={ EarnStep2 } />
                        <Route path="/earn/step/3" exact component={ EarnStep3 } />
                        <Route path="/" exact component={ Landing } />
                        <Route exact path="/">
                            <Redirect to="/" />
                        </Route>
                    </Switch>
                </HashRouter>
                <GlobalStyle />
                <Loader />
                <GenericModal />
                <ToastContainer autoClose={2000} />
            </I18nextProvider>
        );

    }

}

export default connect(null, { openLoader, closeLoader, getProfile })(App);
