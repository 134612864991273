import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dimmer } from 'semantic-ui-react';
import loaderImage from '../../assets/img/loader.svg';
// import './yloader.scss';

class Loader extends React.Component {

    render() {

        return this.props.open ? (
            <Dimmer active style={{ position: 'fixed' }}>
                <img src={ loaderImage } alt="loader" />
            </Dimmer>
        ) : '';

    }

}

const mapStateToProps = state => _.cloneDeep(state.loader);

export default connect(mapStateToProps)(Loader);