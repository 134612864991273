//libraries
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';
import { Trans } from 'react-i18next';

const handleColorType = variant => {
    switch (variant) {
      case "correct":
        return "var(--brand-positive)";
      case "incorrect":
        return "var(--red-color-secondary)";
      default:
        return "var(--brand-positive)";
    }
  };

const MessagerBox = styled.div`
    display: block;
    color: white;
    font-family: PoppinsRegular;
    font-size: 14px;
    padding: .5833em .833em;
    border-radius: .28571429rem;
    margin-top: 15px;
    margin-bottom: 15px;
    a{
        color: white!important;
        text-decoration: underline;
    }

    background: ${({$variant}) => handleColorType($variant)};
`;

const Messager = (props) => {

    const [message, setMessage] = useState('');

    useEffect(()=> {

        switch(props.code) {

            case 'error-login':
                setMessage(
                    <>
                        <p>
                            <Trans i18nKey="messager.errorLogin">
                                <Link to="/auth/recovery/step/1"></Link>
                            </Trans>
                        </p>
                    </>
                );
                break;
            case 'error-recovery':
                setMessage(
                    <>
                        <p>{I18n.t('messager.errorRecovery')}</p>
                    </>
                );
                break;
            case 'register-repeat-email':
                setMessage(
                    <>
                        <p>{I18n.t('messager.errorAlreadyExistEmail')}</p>
                    </>
                )
                break;
            case 409:
                setMessage(<p>{I18n.t(`status.code.${props.code}`)}</p>)
                break;
            case 'correct-recovery':
                setMessage(<p>{I18n.t('messager.instructionSend')}</p>)
                break;
            default:
                setMessage(<p>{I18n.t(`messager.errorWithoutCode`)}</p>)

        }

    },[props.code]);

    return (
        <React.Fragment>
            {props.active && 
            <MessagerBox $variant={props.$variant}>
                {message}
            </MessagerBox>}
        </React.Fragment>
    );

}

export default Messager;