import styled from 'styled-components/macro';
import { flexbox, AlignCenter, XYCenter, ObjFit, PrimaryFont, paddingAuthBox, SecondaryFont, paddSeparator} from './helpers';
/************************** SHARED COMPONENTS STYLES ************************************/

const AuthBox = styled.div`
    width:100%;
    max-width: 558px;
    background:var(--brand-white);
    border-radius: 18px;
    ${paddingAuthBox};
    overflow:hidden;
   
`;

const AuthCol = styled.div`
    ${flexbox};
    ${XYCenter};
    position:relative;
    z-index:3;
    flex-grow:0;
    flex-shrink:1;
    flex-basis:100%;
    min-width:0;
    @media (max-width:559px) {
        background:var(--brand-white);
    }

`;

const AuthRow = styled.div`
    ${flexbox};
    width: 100%;
    min-height: 100%;

`;

const AuthSeparator = styled.div`
    ${paddSeparator};
`;

const AuthHeader = styled.div`
    ${flexbox};
    ${XYCenter};
    flex-direction:${p => p.column ? 'column' : 'row'};
`;

const AuthIcon = styled.div`
    width:${p => p.w ? `${p.w}px` : '46px'};
    height:${p => p.h ? `${p.h}px` : '46px'};
    img {
        ${ObjFit};
    }
`;



const AuthTitle = styled.div`
    ${PrimaryFont}; 
    font-size:20px;
    margin-top:21px;
    @media (max-width:420px) {
        font-size:18px;
    }
`;

const AuthRRSS = styled.div`
    ${flexbox};
    ${AlignCenter};
    padding:9px 14px;
    border-color:${p => p.google && 'var(--border-google-color)' };
    border-color:${p => p.facebook && 'var(--border-facebook-color)' };
    border-width:1px;
    border-style:solid;
    margin-bottom:16px;
    border-radius: 5px;
    overflow:hidden;
    cursor:pointer;
    @media (max-width:420px) {
        padding:6px 10px;
    }
`;

const AuthRRSSTitle = styled.span`
    ${SecondaryFont};
    font-size:14px;
    margin-left: 14px;
`;

const AuthRRSSIcon = styled.div`
    width:28px;
    height:28px;
    flex-shrink:0;
    flex-basis:auto;
    img {
        ${ObjFit};
    }
`;

const BrandIcon = styled.div`
    width:80px;
    height:80px;
    img {
        ${ObjFit};
    }
`;

const AuthLine = styled.div`
    ${SecondaryFont};
    font-size:14px;
    color: var(--brand-primary);
    text-align: center;
    position: relative;
    z-index: 1;
    span{
        display: inline-block;
        background-color: white;
        padding: 0 10px;
    }
    &::after {
        content: "";
        position: absolute;
        height: 1px;
        top:calc(50% - 1px);
        width: 100%;
        background-color: var(--brand-primary);
        left:0;
        z-index: -1;
    }
`;

const AuthMetas = styled.div`
    max-width:500px;
    margin-top:150px;
`;

const AuthMetaTitle = styled.div`
    ${PrimaryFont};
    font-size:36px;
    color: var(--brand-white);
    margin-top:37px;
    margin-bottom:28px;
    p {
        line-height: 45px;
    }
`;

const AuthMetaDescription = styled.div`

    ${SecondaryFont};
    font-size:28px;
    color: var(--brand-white);
    span {
        ${PrimaryFont};
    }
`;

const AuthCTALogin = styled.div`
    span {
        display:inline-block;
    }
    span + span {
        margin-left:10px;
    }

`;

const AuthCTARegister = styled(AuthCTALogin)`

    span + span {
        margin-left:10px;
    }

`;

export {
    AuthCTALogin,
    AuthCTARegister,
    AuthBox,
    AuthHeader,
    AuthIcon,
    AuthTitle,
    AuthRRSS,
    AuthRRSSTitle,
    AuthRRSSIcon,
    AuthSeparator,
    AuthLine,
    BrandIcon,
    AuthMetaTitle,
    AuthMetaDescription,
    AuthMetas,
    AuthRow,
    AuthCol    
}