import styled,{css} from 'styled-components/macro';
import { device } from '../../../styled/mbreakpoints';

const WrapperTransaction = styled.div`
    width:100%;
    max-width:1110px;
    margin:0 auto;
    padding-bottom:3rem;
`;

const HistoryDetails = styled.div`
    padding:1rem;
    background:rgba(241,244,244,0.77);
    opacity:0;
    height:0;

    transition: height 0.5s linear;
    @media ${ device.tablet } {
        padding:1rem 2rem;
    }
`;

const HistoryInfo = styled.div`
    cursor:pointer;
    ${({$selectedRow}) => $selectedRow && css`
        ${HistoryDetails} {
            opacity:1;
            height:auto;
        }

    `};

`;

const HistoryRow = styled.div`
    display:flex;
    padding:1rem 0;
    background:#fff;
    align-items:center;
    border-top: 1px solid #ECECEC;
    &:last-child {
        border-bottom: 1px solid #ECECEC;
    }
    @media ${ device.tablet } {
        padding:1rem 2rem;
    }

`;

const HistoryActionIcon = styled.div`
    flex-shrink:0;
    width:26px;
    height:26px;
    background:#27948F;
    border-radius:50%;
    display:flex;
    i {
        margin:auto;
        height:auto;
        width:auto;
        &:before{
            color:#fff;
        }
    }
`;

const HistoryActionInfo = styled.div`
    padding:0 2rem;
    flex:1;
    p {
        margin-bottom:0;
    }
`;

const HistoryAmount = styled.div`
    margin-left:auto;
    padding-left:1rem;
    flex:0 1 auto;
    p {
        margin-bottom:0;
    }
`;

const HistoryActions = styled.div`
    width:100%;
    display:flex;
    justify-content:flex-end;
    margin-top:0.5rem;
`;

const FiltersWrapper = styled.div`
    width:100%;
    max-width:1110px;
    margin:0 auto;
`;


export {
    WrapperTransaction,
    HistoryActions,
    HistoryRow,
    HistoryActionIcon,
    HistoryActionInfo,
    HistoryAmount,
    FiltersWrapper,
    HistoryDetails,
    HistoryInfo
}