import React from 'react'
import * as S from './styled';
import {Icon} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

function BottomNav({active}) {
    
    return (
        <S.BottomNav role="tablist" >
            <S.BottomNavItem role="tab" $active={active === 'panel'} as={Link} to="/panel">
                <Icon name="home" />
            </S.BottomNavItem>
            <S.BottomNavItem role="tab" $active={active === 'transactions'} as={Link} to="/panel/transactions/history">
                <Icon name="history" />
            </S.BottomNavItem>
            <S.BottomNavItem role="tab" $active={active === 'affiliates'} as={Link} to="/panel/affiliates">
                <Icon name="users" />
            </S.BottomNavItem>
        </S.BottomNav>
    )
}

export default BottomNav
