import styled from 'styled-components/macro';
import { device } from '../../../styled/mbreakpoints';


const Footer = styled.footer`

    padding: 3rem 2rem;
    background-color: var(--brand-primary);
    overflow: hidden;
    

`;

const FooterContent = styled.div`
    width: 100%;
    max-width: var(--max-width-container);
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${ device.tablet } {
        flex-direction: row;
        align-items: flex-start;
    }
`;

const FooterNavContainer = styled.nav`
    margin-bottom: 3rem;
    @media ${ device.tablet } {
        margin-right: auto;
        margin-bottom:0;
    }
`;

const FooterLinks = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    @media ${ device.tablet } {
        flex-direction: row;
        
    }
`;

const FooterLink = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-family:var(--font-secondary);
    a {
        color: #fff;
        text-decoration: none;
    }

    @media ${ device.tablet } {
        margin-bottom: 0;
        margin-right: 4rem;
        &:last-child {
            margin-right: 0;
        }   
    }
   
`;

const SocialLinksContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${ device.tablet } {
        align-items: flex-end;
        p {
            text-align:start;
        }
    }
    p {
        text-align:center;
        margin-bottom:0.6rem;
    }
`;

const FooterLogo = styled.div`
    display:flex;
    align-items:center;
    margin-bottom: 2rem;
    img {
        width:3rem;
        flex:none;
    }

    span {
        margin-left:0.5rem;
        font-size:2rem;
        font-family:var(--font-primary);
        color:#fff;
    }
`;

const SocialLinks = styled.div`
    margin-bottom: 2rem;
    &:not(last-child) {
        margin-bottom: 2rem;
    }

    a, span {
        margin-right:1rem;
        text-decoration: none;
        i {
            &:before{
                color:#fff;
            }
        }
    }

    @media ${ device.tablet } {
        margin-bottom: 0;
        
    }
    
`;


export {
    Footer,
    FooterContent,
    FooterNavContainer,
    FooterLinks,
    FooterLink,
    SocialLinksContainer,
    SocialLinks,
    FooterLogo
}