import React from 'react'
import * as S from './styled';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import I18n from '../../../i18n';

function Goback({iconSize="large", to = "#",  ...rest}) {
    
    return (
        <S.GoBack {...rest} as={Link} to={to}>
            <Icon name="arrow left" size={iconSize} />
            <span className="caption regular">{I18n.t('buttons.actions.goBack')}</span>
        </S.GoBack>
    )
}

export default Goback;