import styled, {css} from 'styled-components/macro';

const BottomNav = styled.div`
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 2;
    height: 48px;
    border-top: 1px solid rgba(255,255,255,0.102);
    background: #fff;
    @media(min-width:768px) {
        display:none;
    }
`;

const BottomNavItem = styled.div`
    flex:1;
    min-width: 0;
    display:flex;
    padding: 0.5rem;
    align-items:center;
    justify-content:center;
    i {
        width:auto;
        height:auto;
        margin:0;
        font-size: 22px;
        &:before{
            color:var(--brand-primary);
        }
        ${({$active}) => $active && css`
        
            &:before{
                color:var(--brand-secondary);
            }

        `};
    }

    
`;

export {
    BottomNav,
    BottomNavItem
}