import React from 'react'
import { Icon } from 'semantic-ui-react';
import I18n from '../../../i18n';
import * as S from './styled';
import IconYGB from '../../../ui/assets/img/coin_3.png';

const URLS = {

    documentation : "https://wiki.yogabot.io/",
    faq : "https://wiki.yogabot.io/faq/sobre-stellar",
    disclaimer: {
        es: "https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/resources/ygb_documents/disclaimer_ygb_ES.pdf",
        en: "https://s3-yogabot-app.s3.eu-west-1.amazonaws.com/resources/ygb_documents/disclaimer_ygb_EN.pdf"
    },
    rrss: {
        twitter: "https://twitter.com/yogabot_ygb",
        telegram: "https://t.me/yogabot_ygb"
    }
}

export function Footer(props) {

    const lang = I18n.languages[0];
    
    return (
        <S.Footer>
            <S.FooterContent>
                <S.FooterNavContainer>
                    <S.FooterLogo>
                        <img src={IconYGB} alt="IconYGB" />
                        <span>Yogabot YGB</span>
                    </S.FooterLogo>
                    <S.FooterLinks>
                        <S.FooterLink>
                            <a target="_blank" rel="noopener noreferrer" href={URLS.documentation}>{I18n.t('footer.links.l1')}</a>
                        </S.FooterLink>
                        <S.FooterLink>
                            <a target="_blank" rel="noopener noreferrer" href={URLS.disclaimer[lang]}>{I18n.t('footer.links.l2')}</a>
                        </S.FooterLink>
                        <S.FooterLink>
                            <a target="_blank" rel="noopener noreferrer" href={URLS.faq}>{I18n.t('footer.links.l3')}</a>
                        </S.FooterLink>
                    </S.FooterLinks>
                </S.FooterNavContainer>
                <S.SocialLinksContainer>
                    <S.SocialLinks>
                        <a target="_blank" rel="noopener noreferrer" href={URLS.rrss.telegram}>
                            <Icon name="telegram" size="big" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href={URLS.rrss.twitter}> 
                            <Icon name="twitter square" size="big"/>
                        </a>
                    </S.SocialLinks>
                    <p className="paragraph regular white">{I18n.t('footer.copy', {year: new Date().getFullYear()})}</p>
                    <p className="caption regular white">{I18n.t('footer.contactUs')} <span className="caption white">yogabot@yogabot.app</span></p>
                </S.SocialLinksContainer>
            </S.FooterContent>
        </S.Footer>
    )
}
