import React, { Component } from 'react'
import Nav from '../../../../components/nav/Nav';

import { ButtonWrapper, FlexWrapper, Inner, Separator } from '../../../../styled/wrappers';
import { LandingWrap, Section, SectionHero } from '../../styled';
import HeroImage from '../../../../assets/img/tokennomics.png';
import OButton from '../../../../styled/button';
import { YCell, YRow, YTable } from './styled';
import BgHero from '../../../../assets/img/fondo_ecosystem.jpg';
import { TimeLineItem, TimeLineItemDate, TimeLineItemPoints, TimeLineRow, TimeLineWrapper } from '../roadmap/components/timeline/styled';
import I18n from '../../../../../i18n';
import IconYgb from '../../../../assets/img/coin_3.png';
import { Footer } from '../../../../components/footer/Footer';

class Tokenomics extends Component {

    render() {

        return (
            <LandingWrap>
              <Nav {...this.props} active="tokenomics"/>
                <SectionHero bg={BgHero}>
                    <Separator />
                    <Inner hero>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={HeroImage} alt="tokennomics" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-1 white">{I18n.t('landing.tokenomics.section1.title')}</p>
                                    <p className="heading-2 regular white">{I18n.t('landing.tokenomics.section1.subtitle')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.startNow')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </SectionHero> 
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section2.title')}</p>
                                <Separator />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <YTable>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row1.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row1.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row2.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row2.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row3.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular"><img className="ygbIcon" src={IconYgb} alt="IconYgb" /></p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row4.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row4.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row5.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row5.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row6.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section2.table.row6.cell2')}</p></YCell>
                                    </YRow>
                                </YTable>
                                <Separator />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <Separator />
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section3.title')}</p>
                                <Separator />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <YTable>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row1.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row1.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row2.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row2.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row3.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row3.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row4.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row4.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row5.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row5.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row6.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row6.cell2')}</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row7.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section3.table.row7.cell2')}</p></YCell>
                                    </YRow>
                                </YTable>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section>
                    <Inner forRead>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section4.title')}</p>
                                <Separator />
                                <p className="paragraph alignCenter regular">{I18n.t('landing.tokenomics.section4.p1')}</p>
                                <Separator />
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section5.title')}</p>
                                <Separator />
                                <p className="paragraph regular alignCenter" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section5.p1')}}></p>
                            </div>
                        </div>
                    </Inner>
                    <Separator xL />
                    <Inner>
                        <TimeLineWrapper>
                            <TimeLineRow $start>
                                <TimeLineItem $start>
                                    <TimeLineItemPoints>
                                        <p className="paragraph regular">{I18n.t('landing.tokenomics.section6.phases.phase1.p1')}</p> 
                                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section6.phases.phase1.p2')}}></p> 
                                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section6.phases.phase1.p3')}}></p> 
                                    </TimeLineItemPoints>
                                    <TimeLineItemDate>
                                        <span className="paragraph white">{I18n.t('landing.tokenomics.section6.phases.phase1.title')}</span>
                                    </TimeLineItemDate>
                                </TimeLineItem>
                            </TimeLineRow>
                            <TimeLineRow >
                                <TimeLineItem >
                                    <TimeLineItemPoints>
                                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section6.phases.phase2.p1')}}></p> 
                                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section6.phases.phase2.p2')}}></p> 
                                    </TimeLineItemPoints>
                                    <TimeLineItemDate>
                                        <span className="paragraph white">{I18n.t('landing.tokenomics.section6.phases.phase2.title')}</span>
                                    </TimeLineItemDate>
                                </TimeLineItem>
                            </TimeLineRow>
                            <TimeLineRow $start>
                                <TimeLineItem $start>
                                    <TimeLineItemPoints>
                                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.tokenomics.section6.phases.phase3.p1')}}></p> 
                                    </TimeLineItemPoints>
                                    <TimeLineItemDate>
                                        <span className="paragraph white">{I18n.t('landing.tokenomics.section6.phases.phase3.title')}</span>
                                    </TimeLineItemDate>
                                </TimeLineItem>
                            </TimeLineRow>
                        </TimeLineWrapper>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section7.title')}</p>
                                <p className="heading-2 alignCenter">{I18n.t('landing.tokenomics.section7.subtitle')}</p>
                                <Separator />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                <YTable>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section7.table.row1.cell1')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section7.table.row1.cell2')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section7.table.row1.cell3')}</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">{I18n.t('landing.tokenomics.section7.table.row1.cell4')}</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">1</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">1.800.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0100</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0115</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">2</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">7.000.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0120</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0126</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">3</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">8.750.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0130</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0136</p></YCell>
                                    </YRow>
                                    <YRow>
                                        <YCell><p className="paragraph-to-caption regular">4</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">7.000.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0140</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0146</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">5</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">7.000.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0150</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0156</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">6</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">5.250.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0170</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0176</p></YCell>
                                    </YRow>
                                    <YRow> 
                                        <YCell><p className="paragraph-to-caption regular">7</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">3.200.000</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0193</p></YCell>
                                        <YCell><p className="paragraph-to-caption regular">0.0200</p></YCell>
                                    </YRow>
                    
                                </YTable>
                                <Separator />
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Footer />   
            </LandingWrap>
        );

    }

}

export default Tokenomics