import styled, {css} from 'styled-components/macro';

const PackageCard = styled.button`
    
    background:transparent;
    border:none;
    outline:0;
    overflow:hidden;
    width:100%;
    cursor:pointer;
`;

const SelectedIndicator = styled.div`
    width:22px;
    height:22px;
    flex-shrink:0;
    border-radius:50%;
    margin-bottom:1rem;
    background: #fff;
    box-shadow: 0px 0px 0px 1px #bcbcbc;
    margin-right:auto;
    display:flex;
    align-items:center;
    justify-content:center;
    i {
        opacity:0;
        
    }
`;

const PackageCardItem = styled.div`
    
    background:#fff;
    border: 2px solid #000000;
    border-radius: 5px;
    padding:1rem;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    ${({$selected}) => $selected && css`
    
        border: 2px solid var(--brand-secondary);
        ${SelectedIndicator} {
            background: var(--brand-secondary);
            box-shadow: none;
            
            i { 
                width:auto;
                height:auto;
                margin:0;
                opacity:1;
                &::before{
                    color:#fff;
                }
            }
        }
    `};
`;

const PaymentWrapper = styled.div`
    max-width: 500px;
    width: 100%;
    margin: 0 auto;

`;

const PaymentHeader = styled.div`
    background:var(--brand-primary);
    i { 
        width:auto;
        height:auto;
        margin:0;
        &::before{
            color:#fff;
        }
    }

    display:flex;
    justify-content:space-between;
    align-items:center;
    padding:1rem ;
`;

const HeaderTotalInfo = styled.div``;

const MethodSeparator = styled.div`
    p{
        color:#afadad;
    }
    
    margin-bottom: 0.8rem;
    border-bottom:2px solid #afadad;;
    padding:1rem 0;
`;
const PaymentBody = styled.div`
    padding:1rem;
    box-shadow:0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    margin-bottom: 1.2rem;
`;

export {
    PackageCard,
    PackageCardItem,
    SelectedIndicator,
    PaymentWrapper,
    PaymentHeader,
    HeaderTotalInfo,
    MethodSeparator,
    PaymentBody
}