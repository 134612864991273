import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import Reducers from './redux-store';
import App from './ui/App';
import KeepAlive from './ui/components/keepAlive/KeepAlive';

import 'semantic-ui-css/semantic.min.css';
import './ui/assets/scss/generic.scss';
import './ui/assets/scss/fonts.scss';
import './ui/assets/scss/typography.scss';
import './ui/assets/scss/grid.scss';
import 'react-toastify/dist/ReactToastify.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    Reducers,
    composeEnhancers(applyMiddleware(ReduxThunk))
);

ReactDOM.render(
    <Provider store={ store }>
        <App />
        <KeepAlive />
    </Provider>,
    document.getElementById('root')
);