import React, { Component } from 'react'
import Nav from '../../../../components/nav/Nav';

import { ButtonWrapper, FlexWrapper, Inner, Separator } from '../../../../styled/wrappers';
import { LandingWrap, Section, SectionHero } from '../../styled';
import HeroImage from '../../../../assets/img/afiliados.png';
import OButton from '../../../../styled/button';
import MImage from '../../../../assets/img/recomienda-amigos.png';
import CImage from '../../../../assets/img/profesional-o-influyente.png';
import CRmage from '../../../../assets/img/oportunity.png';
import BgHero from '../../../../assets/img/fondo_ecosystem.jpg';
import I18n from '../../../../../i18n';
import { Trans } from 'react-i18next';
import { Footer } from '../../../../components/footer/Footer';


class Affiliation extends Component {

    render() {
        
        return (
            <LandingWrap>
              <Nav {...this.props} active="affiliation"/>
                <SectionHero bg={BgHero}>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <img src={HeroImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-1 white">{I18n.t('landing.affiliation.section1.title')}</p>
                                    <p className="heading-2 regular white">{I18n.t('landing.affiliation.section1.subtitle')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.registerasAffiliate')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </SectionHero>
                <Section grayed>
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                               <img src={MImage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.affiliation.section2.title')}</p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.affiliation.section2.p1')}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.affiliation.section2.p2')}}></p>
                                    <Separator />
                                    <Trans i18nKey="landing.affiliation.section2.list">
                                        <ul style={{marginLeft: '1em'}}>
                                            <li><p className="paragraph regular"></p></li>
                                            <li><p className="paragraph regular"></p></li>
                                            <li><p className="paragraph regular"></p></li>
                                        </ul>
                                    </Trans>
                                    <Separator />
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.affiliation.section2.p3', {tokenSold: '145.000'})}}></p>
                                    <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('landing.affiliation.section2.p4')}}></p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.registerasAffiliate')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section grayed>
                    <Inner>
                        <div className="row reversing">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.affiliation.section3.title')}</p>
                                    <p className="paragraph regular">{I18n.t('landing.affiliation.section3.p1')}</p>
                                    <p className="paragraph regular">{I18n.t('landing.affiliation.section3.p2')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.registerasAffiliate')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <img src={CImage} alt="" />
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Section >
                    <Inner>
                        <div className="row">
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-5">
                               <img src={CRmage} alt="" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-xl-1">
                                <FlexWrapper justifyCenter fHeight="100%">
                                    <p className="heading-2">{I18n.t('landing.affiliation.section4.title')}</p>
                                    <p className="paragraph regular">{I18n.t('landing.affiliation.section4.p1')}</p>
                                    <p className="paragraph regular">{I18n.t('landing.affiliation.section4.p2')}</p>
                                    <p className="paragraph regular">{I18n.t('landing.affiliation.section4.p3')}</p>
                                    <ButtonWrapper reverse>
                                        <OButton onClick={()=> this.props.history.push('/auth/register')} terciary upper color="#fff" size="large"><span>{I18n.t('buttons.actions.registerasAffiliate')}</span></OButton>
                                    </ButtonWrapper>
                                </FlexWrapper>
                            </div>
                        </div>
                    </Inner>
                </Section>
                <Footer />
            </LandingWrap>
        );

    }

}

export default Affiliation