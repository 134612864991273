import styled from 'styled-components/macro';

const YTable = styled.div`
    background: #FFFFFF;
    box-shadow: 3px 2px 13px 1px rgba(0, 0, 0, 0.07);
`;

const YRow = styled.div`

    display:flex;
    &:nth-child(even) {
        background:#fff;
    }
    &:nth-child(odd) {
        background:rgba(229, 229, 229, 0.77);
    }
`;

const YCell = styled.div`
    flex:1;
    padding:1rem 5%;
`;

export {
    YTable,
    YRow,
    YCell
}