import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import I18n from '../../../i18n';
import {Inner} from '../../styled/wrappers';
import * as S from './styled';

export default function CookiesBox(props) {

    const [open, setOpen] = useState(false);
    
    useEffect(()=> {

        const hasCookies = window.localStorage.getItem('cookiesAccepted') === 'true';

        if (!hasCookies) {

            setOpen(true);

        }


    }, [])

    const closeCookiesBox = () => {

        setOpen(false);

        window.localStorage.setItem('cookiesAccepted', true);

    }

    return open ? (

        <S.Cookies>
            <Inner>
                <p className="caption regular">{I18n.t('cookies.firstMessage')}</p>
                <p className="caption regular">{I18n.t('cookies.secondMessage')}<Link to="/legal/cookies"> {I18n.t('cookies.cookiesPolicyHere')}</Link></p>
            </Inner>
            <S.AcceptCookieBtn onClick={closeCookiesBox}>
                <Icon name='times' size="large" />
            </S.AcceptCookieBtn>
        </S.Cookies>

    ) : null
}
