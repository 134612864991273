import I18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import common_es from './config/locales/es-ES.json';
import common_en from './config/locales/en-EN.json';

const resources = {

    en: { common: common_en },
    es: { common: common_es }
}

const options = {
    order: ['localStorage','navigator'],
    lookupLocalStorage: 'i18nextLng',
    caches: ['localStorage']
    
}

I18n
    .use(LanguageDetector)
    .init({
        whitelist: ['es', 'en'],
        resources,
        load: 'languageOnly',
        nonExplicitSupportedLngs: false,
        detection: options,
        ns: ['common'],
        defaultNS: 'common',
        supportedLngs: ['es', 'en'],
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        }
    });

export default I18n;