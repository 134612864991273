// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm, initialize } from 'redux-form';
import { connect } from 'react-redux';

// Components
import CustomInput from '../../../components/form/input';
import PanelNav from '../../../components/nav/PanelNav';

// Styled components
import { Inner, Separator } from '../../../styled/wrappers';
import { WrapperProfile, ProfileAside, ProfileContent } from '../styled';
import OButton from '../../../styled/button';

// Redux Store
import { updateProfile } from '../../../../redux-store/auth';
import { openToast } from '../../../../redux-store/toast';
import ProfileMenu from './menu/ProfileMenu';

// Locales
import I18n from '../../../../i18n';

class ProfileAccount extends Component {

    componentDidMount() {

        const initialValues = _.merge({ name: '', surname: '' }, this.props.userSession);
        this.props.dispatch(initialize('profileAccountForm', initialValues));

    }

    onUpdateProfile = async formData => {

        try {

            await this.props.updateProfile({ ...formData });
            openToast({ message: I18n.t('messages.dataSaved'), type: 'success' });


        } catch (codeError) {

            openToast({ message: I18n.t(`status.code.${ codeError }`), type: 'error' });

        }

    }

    render() {

        return (
            <>
                <PanelNav active="profileAccount" />
                <Inner>
                    <Separator xL />
                    <WrapperProfile>
                        <ProfileAside>
                            <ProfileMenu active="profileAccount" />
                        </ProfileAside>
                        <ProfileContent>
                            <p className="heading-3">{I18n.t('profile.myData')}</p>
                            <Form name="profileAccountForm" noValidate onSubmit={this.props.handleSubmit(this.onUpdateProfile)}>
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('profile.name') }
                                    name="name"
                                    fieldClasses="label-primary"
                                    label={ I18n.t('profile.name') } />
                                <Field
                                    component={ CustomInput }
                                    placeholder={ I18n.t('profile.surname') }
                                    name="surname"
                                    fieldClasses="label-primary"
                                    label={ I18n.t('profile.surname') } />
                                <OButton upper type="submit" color="#FFF" terciary>
                                    <span>{I18n.t('buttons.actions.saveChanges')}</span>
                                </OButton>
                            </Form>
                        </ProfileContent>
                    </WrapperProfile>
                </Inner>
            </>
        );
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.name)) {

        errors.name = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.surname)) {

        errors.surname = I18n.t('validations.required');

    }

    return errors;

};

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {})
    };

};

export default reduxForm({
    form: 'profileAccountForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate
})(connect(mapStateToProps, { updateProfile })(ProfileAccount));
