import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import Config from '../../../config';

import PaymentModel from '../../../models/payments';

class PaypalButton extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            disabled: false
        };

    }

    onSuccess = async (data, actions) => {

        this.setState({ disabled: true });

        const { amount, currency, userId, tokens } = this.props;

        await actions.order.capture();

        const userPayment = await PaymentModel.createInvestment({
            amount,
            currency,
            platform: 'paypal',
            orderId: data.orderID,
            userId,
            tokens
        });

        this.props.onSuccess(userPayment);

    };

    createOrder = (data, actions) => actions.order.create({
        purchase_units: [{ description: this.props.description, amount: { value: this.props.amount }}],
        order_application_context: { shipping_preferenceenum: 'NO_SHIPPING' }
    });

    render() {

        const { currency, amount } = this.props;
        const { disabled } = this.state;

        const initialOptions = {
            'client-id': Config.paypal.clientId,
            currency,
            'disable-funding': ['bancontact', 'sofort', 'blik', 'eps', 'giropay', 'ideal', 'mercadopago', 'mybank', 'p24', 'sepa', 'venmo'],
            'integration-date': '2021-02-18',
            vault: false,
            intent: 'capture'
        };

        return (
            <PayPalScriptProvider options={ initialOptions }>
                <PayPalButtons style={{ height: 35.6, size: 'responsive' }} disabled={ disabled } forceReRender={ amount } onApprove={ this.onSuccess } createOrder={ this.createOrder } />
            </PayPalScriptProvider>
        );

    }

}

export default PaypalButton;