import React from 'react'
import * as S from './styled';
import { Icon } from 'semantic-ui-react';
import I18n from '../../../../../i18n';

function ProfileMenu({active}) {
    
    return (
        <>
            <S.ProfileMenu>
                <S.ProfileMenuList role="tablist">
                <S.ProfileMenuListItem role="listitem">
                    <S.ProfileMenuItem to="/profile/account" activeLink={active === 'profileAccount'}>
                        <S.ItemIcon><Icon name="user" /></S.ItemIcon>
                        <S.ItemText>{I18n.t('navigation.panel.profile')}</S.ItemText>
                    </S.ProfileMenuItem>
                </S.ProfileMenuListItem>
                <S.ProfileMenuListItem role="listitem">
                    <S.ProfileMenuItem to="/profile/security" activeLink={active === 'profileSecurity'}>
                        <S.ItemIcon><Icon name="lock" /></S.ItemIcon>
                        <S.ItemText>{I18n.t('navigation.panel.password')}</S.ItemText>
                    </S.ProfileMenuItem>
                </S.ProfileMenuListItem>
                <S.ProfileMenuListItem role="listitem">
                    <S.ProfileMenuItem to="/profile/email" activeLink={active === 'profileEmail'}>
                        <S.ItemIcon><Icon name="mail" /></S.ItemIcon>
                        <S.ItemText>{I18n.t('navigation.panel.email')}</S.ItemText>
                    </S.ProfileMenuItem>
                </S.ProfileMenuListItem>
                <S.ProfileMenuListItem role="listitem">
                    <S.ProfileMenuItem to="/profile/preferences" activeLink={active === 'profilePreferences'}>
                        <S.ItemIcon><Icon name="setting" /></S.ItemIcon>
                        <S.ItemText>{I18n.t('navigation.panel.preferences')}</S.ItemText>
                    </S.ProfileMenuItem>
                </S.ProfileMenuListItem>
                <S.ProfileMenuListItem role="listitem">
                    <S.ProfileMenuItem to="/?logout=true" activeLink={false}>
                        <S.ItemIcon><Icon name="log out" /></S.ItemIcon>
                        <S.ItemText>{I18n.t('navigation.panel.logout')}</S.ItemText>
                    </S.ProfileMenuItem>
                </S.ProfileMenuListItem>
                </S.ProfileMenuList>
            </S.ProfileMenu>
        </>
    )
}

export default ProfileMenu;