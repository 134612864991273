import { css } from 'styled-components';
import { device } from './mbreakpoints';

const flex = css`display:flex;`;
const wrap = css`flex-wrap:wrap`;
const alignCenter = css`align-items:center`;
const justifyCenter = css`justify-content:center`;
const alignStart = css`align-items:flex-start`;

const flexCentering = css`
    ${ alignCenter };
    ${ justifyCenter };
`;

const bgcover = css`
    background-size:cover;
    background-position:center center;
    background-repeat:no-repeat;
`;

const bgcontain = css`
    background-size:contain;
    background-position:center center;
    background-repeat:no-repeat;
`;

const column = css`
    flex-direction:column;
`;

const regularPadding = css`

    padding:0 20px;
    @media ${ device.mobileL } { 
        padding:0 32px;
    }

`;

export {
    flex,
    wrap,
    column,
    justifyCenter,
    alignCenter,
    flexCentering,
    alignStart,
    bgcover,
    bgcontain,
    regularPadding
};