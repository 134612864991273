import styled,{css} from 'styled-components/macro';
import React from 'react';
import { Link } from 'react-router-dom';

const ProfileMenu = styled.div`
    padding:1rem;
`;

const ProfileMenuList = styled.ul`
    list-style:none;

`;
const ProfileMenuListItem = styled.li`
    margin-bottom:1rem;
`;

const ProfileMenuItem = styled(({activeLink, ...rest})=> <Link  { ...rest} />)`
    display: flex;
    align-item:center;
    color:var(--brand-primary);
    transition: color .1s ease-in;
    font-size:1rem;
    font-family: var(--font-secondary);
    text-decoration:none;
    &:hover,focus,active {
        color:var(--brand-secondary);
        i {
            &:before{
                color:var(--brand-secondary);
            }
        }
    }

    ${({activeLink}) => activeLink && css`
        font-family: var(--font-primary);
    `};
`;

const ItemIcon = styled.span`
    flex:0 0 16px;
    margin-right:1.2rem;
    min-width:0;
    i {
        width:auto;
        height:auto;
        margin:0;
        &:before{
            color:var(--brand-primary);
            transition: color .1s ease-in;
        }
    }
`;

const ItemText = styled.span`
    flex:1;
`;

export {
    ProfileMenu,
    ProfileMenuList,
    ProfileMenuListItem,
    ProfileMenuItem,
    ItemIcon,
    ItemText

}