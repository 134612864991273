import React, { Component } from 'react';
import PanelNav from '../../../components/nav/PanelNav';
import { Inner, Separator } from '../../../styled/wrappers';
import { WrapperProfile, ProfileAside, ProfileContent } from '../styled';
import {Form, Radio} from 'semantic-ui-react';
import I18n from '../../../../i18n';

import ProfileMenu from './menu/ProfileMenu';


class ProfilePreferences extends Component {

    constructor(props){
        super(props);
        this.state = {
            lang: "" 
        }
    }

    componentDidMount() {

        this.setState({lang: I18n.languages[0]})
    }

    handleChange = (e, { value }) => I18n.changeLanguage(value, () => window.location.reload());

    render() {

        const { lang } = this.state;

        return (
            <>
                <PanelNav active="profilePreferences" />
                <Inner>
                    <Separator xL />
                    <WrapperProfile>
                        <ProfileAside>
                            <ProfileMenu active="profilePreferences" />
                        </ProfileAside>
                        <ProfileContent>
                            <p className="heading-3">{I18n.t('profile.myLang')}</p>
                            <Form>
                                <Form.Field>
                                    <Radio
                                        label={I18n.t('languages.es')}
                                        name='radioGroup'
                                        value='es'
                                        checked={lang === 'es'}
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Radio
                                        label={I18n.t('languages.en')}
                                        name='radioGroup'
                                        value='en'
                                        checked={lang === 'en'}
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Form>
                        </ProfileContent>
                    </WrapperProfile>
                </Inner>
            </>
        );
    }
}

export default ProfilePreferences;