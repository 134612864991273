// Third party libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Form } from 'semantic-ui-react';
import queryString from 'query-string';
import ReCAPTCHA from "react-google-recaptcha";
import { Trans } from 'react-i18next';

// Components
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';
import Messager from '../../../components/messager/Messager';

// Styled components
import {
    AuthCTALogin,
    AuthSeparator,
    AuthHeader,
    AuthTitle,
    AuthBox,
    AuthRow,
    AuthCol
} from '../styled';
import OButton from '../../../styled/button';

// Redux actions
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { registerUser } from '../../../../redux-store/auth';

// Locales
import I18n from '../../../../i18n';

const TOS_URLS = {
    es: "https://wiki.yogabot.io/legal/terminos-de-uso",
    en: "https://wiki.yogabot.io/v/english/legal/terms-of-use"
}

const recaptchaRef = React.createRef();

class Register extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            hasError: false,
            codeError: -1,
            correct: false,
            isCaptchaCorrect: false
        };

    }

    gotoLogin = () => this.props.history.push(`/auth/login`);

    register = async ({email, password, comercialNotifications}) => {

        const { openLoader, closeLoader, registerUser, history } = this.props;
        const referredId = this.getReferredId();
        let isCorrect = false;
        const recaptchaValue = recaptchaRef.current.getValue();

        if (_.isEmpty(recaptchaValue)) {

            return false;

        }

        try {

            openLoader();
            const formValues = { email: _.trim(email), password, comercialNotifications };
            const wizzard = [{
                userAnswer: 3
            }, {
                userAnswer: 1
            }, {
                userAnswer: 2
            }];

            if (referredId) {

                formValues.ref = referredId;

            }

            if (window.localStorage.getItem('wizzard') && _.isEqual(JSON.parse(window.localStorage.getItem('wizzard')), wizzard) ) {

                formValues.learnprize = true;

            }

            await registerUser(formValues);
            isCorrect = true;

        } catch (error) {

            this.setState({ hasError: true, codeError: 'register-repeat-email' });
            isCorrect = false;

        } finally {

            if (isCorrect) {

                if (localStorage.getItem('ref')) {

                    localStorage.removeItem('ref');

                }

                if (localStorage.getItem('wizzard')) {

                    localStorage.removeItem('wizzard');

                }

                history.push('/panel');

            }

            closeLoader();

        }

    }

    getReferredId = () =>  {

        let referredId = null;

        if (localStorage.getItem('ref')) {

            referredId = localStorage.getItem('ref');

        } else if (_.get(this.props, 'location.search', false)) {

            const queryValues = queryString.parse(this.props.location.search);

            if (queryValues.ref) {

                referredId = queryValues.ref;

            }

        }

        return referredId;

    }

    /**
     * @desc values can null in case of expired or error otherwise with a token
     * */
    onChangeCaptcha = value => this.setState({ isCaptchaCorrect: !!value });

    render() {

        const { hasError, codeError, isCaptchaCorrect } = this.state;
        const { tos } = this.props;
        const lang = I18n.languages[0];

        return (
            <>
                <AuthRow>
                    <AuthCol>
                        <AuthBox>
                            <AuthHeader column>
                                <AuthTitle><span>{ I18n.t('register.title') }</span></AuthTitle>
                            </AuthHeader>
                            <AuthSeparator />
                            <Messager active={ hasError } code={ codeError } variant="incorrect"/>
                            <Form name="registerFillForm" noValidate onSubmit={ this.props.handleSubmit(this.register) }>
                                <AuthSeparator />
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ I18n.t('register.email') }
                                        name="email"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t('register.email')}
                                        restrictions={ [{ trim: true }] }
                                    />
                                    <Field
                                        component={ CustomInput }
                                        placeholder={ "" }
                                        name="password"
                                        type="password"
                                        fieldClasses="y-input default small"
                                        label={ I18n.t("register.password") }
                                    />
                                <AuthSeparator />
                                <AuthSeparator>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey="6LeuyvIUAAAAAH10pcg0jS9x8YHTBasE9sV3rcYo"
                                        onChange={this.onChangeCaptcha}
                                        className="captcha"
                                    />
                                </AuthSeparator>
                                <AuthSeparator>
                                    <Field
                                        component={ CustomCheckBox }
                                        name='tos'
                                        fieldClasses="y-checkbox small inline-block"
                                        label={ '' }
                                    />
                                    <label className="font-secondary">
                                        <Trans i18nKey="register.acceptTos">
                                            <a target="_blank" rel="noopener noreferrer" href={ TOS_URLS[lang] }>&nbsp;</a>
                                        </Trans>
                                    </label>
                                    <Field
                                        component={ CustomCheckBox }
                                        name='comercialNotifications'
                                        fieldClasses="y-checkbox small"
                                        label={ I18n.t("register.acceptNotifications") }
                                    />
                                </AuthSeparator>
                                <AuthSeparator>
                                    <OButton upper type="submit" disabled={!tos || !isCaptchaCorrect } fluid color="#FFF" terciary>
                                        <span>{ I18n.t('buttons.actions.createAccount') }</span>
                                    </OButton>
                                </AuthSeparator>
                                <AuthSeparator>
                                    <AuthCTALogin>
                                        <span className="labeling regular">{ I18n.t('register.hasAccount') }</span>
                                        <span className="labeling regular brand c-pointer" onClick={ this.gotoLogin }>{ I18n.t('buttons.actions.loginNow') }</span>
                                    </AuthCTALogin>
                                </AuthSeparator>
                            </Form>
                        </AuthBox>
                    </AuthCol>
                </AuthRow>
            </>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');

    }

    return errors;

};


const selector = formValueSelector('registerFillForm');

Register = connect(state => {

    return {
        tos: selector(state, 'tos')
    };

})(Register);

export default reduxForm({
    form: 'registerFillForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        comercialNotifications: false
    }
})(connect(null, { openLoader, closeLoader,  registerUser })(Register));