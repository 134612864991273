import AxiosService from '../../utils/AxiosService';
import Config from '../../config';

const checkJwt = async () => await AxiosService.get(Config.server.api.securityApi + 'io/check', true);
const login = async payload => await AxiosService.post(Config.server.api.securityApi + 'io/login', payload, false);
const register = async payload => await AxiosService.post(Config.server.api.securityApi + 'io/register', payload, false);
const changePassword = async payload => await AxiosService.post(Config.server.api.securityApi + 'io/changepassword', payload, true);
const changeEmail = async payload => await AxiosService.post(Config.server.api.securityApi + `io/changeemail`, payload , true);
const getProfile = async () => await AxiosService.get(Config.server.api.securityApi + 'io/getprofile', true);
const removeAccount = async () => await AxiosService.post(Config.server.api.securityApi + `io/removeaccount`, {}, true);
const removeAccountConfirmation = async token => await AxiosService.post(Config.server.api.securityApi + `io/removeaccount/${token}`, {}, false);
const recoveryPassword = async payload =>  await AxiosService.post(Config.server.api.securityApi + `io/recoverypassword/${payload.email}`, {}, false);//step1
const setRecoveryPassword = async payload => await AxiosService.post(Config.server.api.securityApi + `io/recoverypassword/${payload.token}/set`, {newPassword: payload.newPassword }, false);//step2
const verifyEmail = async token => await AxiosService.post(Config.server.api.securityApi + `io/verifyemail/${token}`, {}, false);
const updateProfile = async payload => await AxiosService.post(Config.server.api.securityApi + 'io/saveprofile', payload, true);

const exports = {
    checkJwt,
    login,
    register,
    changePassword,
    changeEmail,
    getProfile,
    removeAccount,
    removeAccountConfirmation,
    recoveryPassword,
    setRecoveryPassword,
    verifyEmail,
    updateProfile
}
export default exports;

/* <securityAPI>/security/io/login --> Sólo cambia la ruta, el resto igual que en Yogabot.app (salvo que no tengo en cuenta el remember)
<securityAPI>/security/io/register --> Sólo cambia la ruta, el resto igual que en Yogabot.app (aquí no tengo campos obligados, pero habría que ver qué ponen en el figma para saber qué guardo, por el momento se guarda todo lo que me mandes)
<securityAPI>/security/io/changepassword --> Sólo cambia la ruta, el resto igual que en Yogabot.app
<securityAPI>/security/io/check --> Sólo cambia la ruta, el resto igual que en Yogabot.app
<securityAPI>/security/io/changeemail --> Sólo cambia la ruta, el resto igual que en Yogabot.app
<securityAPI>/security/io/getprofile --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado)
<securityAPI>/security/io/removeaccount --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado) <-- Esta es la dirección a la que llamas cuando confirmas la eliminación de la cuenta (tras el enlace del correo)
<securityAPI>/security/io/removeaccount/{token} --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado)
<securityAPI>/security/io/recoverypassword/{email} --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado)
<securityAPI>/security/io/recoverypassword/{token}/set --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado) <-- Esta es la dirección a la que llamas para el cambio de clave (tras el enlace del correo)
<securityAPI>/security/io/saveprofile --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado)
<securityAPI>/security/io/verifyemail/{token} --> Sólo cambia la ruta, el resto igual que en Yogabot.app (editado) <-- Esta es la dirección a la que llamas cuando confirmas el correo o cuando confirmas el cambio de correo (tras el enlace del correo) (editado)  */