// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { Icon, Form, Select, Checkbox } from 'semantic-ui-react';
import { connect } from 'react-redux';
import Moment from 'moment';

// Components
import PanelNav from '../../components/nav/PanelNav';
import BottomNav from '../../components/bottomNav/BottomNav';
import { NumericFormat } from '../../components/numericFormat/NumericFormat';

// Styled components
import { Wrapper, Inner, Separator } from '../../styled/wrappers';
import { WrapperTransaction, HistoryRow, HistoryActionIcon, HistoryActionInfo, HistoryAmount, FiltersWrapper, HistoryDetails, HistoryInfo, HistoryActions } from './styled';
import OButton from '../../styled/button';

// Models
import PaymentModel from '../../../models/payments';

// Redux
import { openLoader, closeLoader } from '../../../redux-store/loader';
import { getTransactionMovements } from '../../../redux-store/transactions';

// Locales
import I18n from '../../../i18n';

const TYPES = {
    prize: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: I18n.t('panel.transactions.prize')
    },
    blocked: {
        icon: <Icon name='lock' className='' />,
        text: I18n.t('panel.transactions.blocked')
    },
    'cancelled-reception': {
        icon: <Icon name='user cancel' className='' />,
        text: I18n.t('panel.transactions.cancelledReception')
    },
    'cancelled-sending': {
        icon: <Icon name='user cancel' className='' />,
        text: I18n.t('panel.transactions.cancelledSending')
    },
    investment: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: I18n.t('panel.transactions.investment')
    },
    preico: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: <>{ I18n.t('panel.transactions.buy') } <span className='caption regular' style={{color: '#98a3af'}}>({ I18n.t('panel.transactions.preico') })</span></>
    },
    assignment: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: I18n.t('panel.transactions.assignment')
    },
    affiliation: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: I18n.t('panel.transactions.affiliation')
    },
    sent: {
        icon: <Icon name='arrow up' className='rotate45' />,
        text: I18n.t('panel.transactions.sent')
    },
    received: {
        icon: <Icon name='arrow up' className='rotate225' />,
        text: I18n.t('panel.transactions.received')
    },
    'pending-acceptance-send': {
        icon: <Icon name='wait' className='' />,
        text: I18n.t('panel.transactions.pendingAcceptanceSend')
    },
    'pending-acceptance-receive': {
        icon: <Icon name='wait' className='' />,
        text: I18n.t('panel.transactions.pendingAcceptanceReception')
    },
    'pending-receiver-user': {
        icon: <Icon name='wait' className='' />,
        text: I18n.t('panel.transactions.pendingReceiverUser')
    },
    whitdrawals: {
        icon: <Icon name='arrow up' className='rotate45' />,
        text: I18n.t('panel.transactions.whitdrawals')
    }
};

const OPTION_TYPES = [{
    key: 0,
    text: I18n.t('panel.transactions.all'),
    value: 'all'
},
{
    key: 1,
    text: I18n.t('panel.transactions.prize'),
    value: 'prize'
},
{
    key: 2,
    text: I18n.t('panel.transactions.blocked'),
    value: 'blocked'
},
{
    key: 3,
    text: I18n.t('panel.transactions.investment'),
    value: 'investment'
},
{
    key: 4,
    text: I18n.t('panel.transactions.buyPreico'),
    value: 'preico'
},
{
    key: 5,
    text: I18n.t('panel.transactions.assignment'),
    value: 'assignment'
},
{
    key: 6,
    text: I18n.t('panel.transactions.affiliation'),
    value: 'affiliation'
},
{
    key: 7,
    text: I18n.t('panel.transactions.sent'),
    value: 'sent'
},
{
    key: 8,
    text: I18n.t('panel.transactions.received'),
    value: 'received'
},
{
    key: 9,
    text: I18n.t('panel.transactions.pendingAcceptanceSend'),
    value: 'pending-acceptance-send'
},
{
    key: 10,
    text:I18n.t('panel.transactions.pendingAcceptanceReception'),
    value: 'pending-acceptance-receive'
},
{
    key: 11,
    text: I18n.t('panel.transactions.pendingReceiverUser'),
    value: 'pending-receiver-user'
},
{
    key: 12,
    text: I18n.t('panel.transactions.whitdrawals'),
    value: 'whitdrawals'
}];

const OPTION_DIRECTIONS = [{
    key: 0,
    text: I18n.t('panel.transactions.asc'),
    value: 'asc'
},
{
    key: 1,
    text: I18n.t('panel.transactions.desc'),
    value: 'desc'
}];

const setCurrency = (currency, amount) => {

    const format = {
        USD: <NumericFormat value={ amount } type='usd' />,
        EUR: <NumericFormat value={ amount } type='eur' />,
    };

    return format[currency] || <NumericFormat value={ amount } />;

};

const cards = {
    visa : <Icon name='cc visa' size='large' />,
    amex: <Icon name='cc amex' size='large'/>,
    mastercard: <Icon name='cc mastercard' size='large'/>,
    paypal: <Icon name='paypal card' size='large'/>
};

const ViewByType = props => {

    switch(props.type) {

        case 'prize':
        case 'blocked':
        case 'cancelled-reception':
        case 'cancelled-sending':
        case 'investment':
        case 'preico':
        case 'assignment':
        case 'sent':
        case 'received':
        case 'pending-acceptance-send':
        case 'pending-acceptance-receive':

            return (
                <>
                    <p className='labeling'>{ I18n.t('panel.transactions.transactionId') }</p>
                    <p className='labeling regular'>{ props._id }</p>
                    { props.currency &&
                        <>
                            <p className='labeling'>{ I18n.t('panel.transactions.price') }</p>
                            <p className='labeling regular'>{ setCurrency(props.currency, props.amount) }</p>
                        </>
                    }
                    { props?.platform === 'stripe' &&
                        <>
                            <p className='labeling'>{ I18n.t('panel.transactions.paymentMethod') }</p>
                            <p className='labeling regular'>{ cards[props.brand] || <Icon name='credit card' /> } <span>•••• •••• •••• { props.last4 }</span></p>
                        </>
                    }
                    { props?.platform === 'paypal' &&
                        <>
                            <p className='labeling'>{ I18n.t('panel.transactions.paymentMethod') }</p>
                            <p className='labeling regular'>{ cards[props.platform] } PayPal</p>
                        </>
                    }
                </>
            );

        case 'affiliation':

            return (
                <>
                    <p className='labeling'>{ I18n.t('panel.transactions.transactionId') }</p>
                    <p className='labeling regular'>{ props._id }</p>
                    <p className='labeling'>{ I18n.t('panel.transactions.boughtTokens') }</p>
                    <p className='labeling regular'><NumericFormat value={ props.amount * 5 } type='token' /></p>
                </>
            );

        case 'whitdrawals':

            return (
                <>
                    <p className='labeling'>{ I18n.t('panel.transactions.transactionId') }</p>
                    <p className='labeling regular'>{ props._id }</p>
                </>
            );

        default:
            return null

    }

}

class TransactionHistory extends Component {

    constructor(props) {

        super(props);

        this.state = {
            data: [],
            filteredData: [],
            selectedType: 'all',
            selectedDateDirection: 'desc',
            selectedRow: -1,
            seeCancelled: false
        };

    }

    componentDidMount() {

        this.getInfos();

    }

    getInfos = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();
            const data = await this.props.getTransactionMovements();
            this.setState({ data }, () => this.onFilterCompose());

        } catch (error) {

            console.error('error', error);

        } finally {

            closeLoader();

        }

    }

    onFilter = (selectedOption, filterType) => this.setState({ [filterType]: selectedOption }, () => this.onFilterCompose());

    onFilterCompose = () => {

        const { selectedType, selectedDateDirection, seeCancelled, data } = this.state;
        const cancelledMap = {
            'send': 'cancelled-sending',
            'received': 'cancelled-reception'
        }

        const filterType = data => _.filter(data, item => {

            if (selectedType === 'all') {

                return !seeCancelled ? !['cancelled-sending', 'cancelled-reception'].includes(item.type) : item;

            } else {

                return selectedType === item.type || (seeCancelled && cancelledMap[selectedType] === item.type);

            }

        });

        const filterDataDirection = data => _.orderBy(data, ['createdAt'], [selectedDateDirection]);

        const filteredData = _.reduceRight([filterDataDirection, filterType], (c, fn) => fn(c), data);

        this.setState({ filteredData });

    }

    selectRow = selectedRow => this.setState({ selectedRow: selectedRow === this.state.selectedRow ? -1 : selectedRow });

    handleRequest = async (e, action, requestId) => {

        e.stopPropagation();

        try {

            this.props.openLoader();
            await PaymentModel.acceptOrDeclineRequestYGB(requestId, action);

        } catch (error) {

            console.error('error', error);

        } finally {

            this.getInfos();

        }

    }

    onToggleCancelled = () => this.setState(prevState => ({ seeCancelled: !prevState.seeCancelled }), () => this.onFilterCompose());

    renderButtonsActions = ({ type, _id }) => {

        const showCancelButton = ['blocked', 'pending-receiver-user'].includes(type);
        const showCancelAndAcceptButtons = ['pending-acceptance-send', 'pending-acceptance-receive'].includes(type);

        if (showCancelButton) {

            return <OButton type='button' selected color='#1fd5b9' size='mini' onClick={ e => this.handleRequest(e, 'deny', _id) }><span>{ I18n.t('actions.cancel') }</span></OButton>;

        }

        if (showCancelAndAcceptButtons) {

            return (
                <>
                    <OButton type='button' selected color='#1fd5b9' size='mini' onClick={(e) =>this.handleRequest(e,'deny', _id) }><span>{ I18n.t('actions.cancel') }</span></OButton>
                    <OButton type='button' primary color='#fff' size='mini' onClick={(e) => this.handleRequest(e,'accept', _id) }><span>{ I18n.t('actions.accept') }</span></OButton>
                </>
            );

        }

        return null;

    }

    render() {

        const { filteredData = [], selectedType, selectedDateDirection, selectedRow, seeCancelled } = this.state;

        return (
            <Wrapper>
                <PanelNav active='transactions' />
                <Inner>
                    <Separator xL/>
                        <div className='i-back' onClick={ () => this.props.history.push('/panel') }><Icon name='angle left' /><span>{ I18n.t('buttons.actions.goBack') }</span></div>
                    <Separator/>
                    <FiltersWrapper>
                        <Form>
                            <Form.Group inline>
                                <Form.Field>
                                    <Select value={ selectedType } name='selectedType' onChange={ (e, { value, name }) => this.onFilter(value, name) } options={ OPTION_TYPES } />
                                </Form.Field>
                                <Form.Field>
                                    <Select value={ selectedDateDirection } name='selectedDateDirection' onChange={ (e, { value, name }) => this.onFilter(value, name) } options={ OPTION_DIRECTIONS } />
                                </Form.Field>
                                <Form.Field>
                                    <Checkbox label='Ver canceladas' checked={seeCancelled} onChange={this.onToggleCancelled} />
                                </Form.Field>
                            </Form.Group>
                        </Form>
                    </FiltersWrapper>
                    <WrapperTransaction>
                        { filteredData.length === 0 && <><Separator /><p className='paragraph regular'>{ I18n.t('messages.emptyResult') }</p></> }
                        { filteredData.map((item, index) => (
                            <HistoryInfo key={ item._id } onClick={ () => this.selectRow(index) } $selectedRow={ selectedRow === index } role='button'>
                                <HistoryRow isCancelled={item.type.includes('cancelled')}>
                                    <HistoryActionIcon>
                                        { TYPES[item.type]?.icon }
                                    </HistoryActionIcon>
                                    <HistoryActionInfo>
                                        <p className='paragraph'>{ TYPES[item.type]?.text }</p>
                                        <p className='caption regular'>{ Moment(item.createdAt).format('DD/MM/YYYY HH:mm') }</p>
                                    </HistoryActionInfo>
                                    <HistoryAmount>
                                        <p className='paragraph'>{ ['blocked', 'cancelled-sending', 'sent', 'whitdrawals', 'pending-acceptance-send'].includes(item.type) ? '-' : '' }<NumericFormat value={ item.tokens } type='token' suffix=' YGB'/></p>
                                        <p className='caption regular'>{I18n.t('panel.resultBalance')} (<NumericFormat value={ item.balance } type='token' suffix=' YGB'/>)</p>
                                    </HistoryAmount>
                                    { ['blocked', 'pending-receiver-user', 'pending-acceptance-send', 'pending-acceptance-receive'].includes(item.type) &&
                                    <HistoryActions>
                                        { this.renderButtonsActions(item) }
                                    </HistoryActions>}
                                </HistoryRow>
                                { selectedRow === index &&
                                    <HistoryDetails>
                                        <ViewByType { ...item } />
                                    </HistoryDetails>
                                }
                            </HistoryInfo>
                        )) }
                    </WrapperTransaction>
                </Inner>
                <BottomNav active='transactions' />
            </Wrapper>
        );

    }

}

export default connect(null, { openLoader, closeLoader, getTransactionMovements })(TransactionHistory);