// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

// Components
import BottomNav from '../../../components/bottomNav/BottomNav';
import Goback from '../../../components/goBack/GoBack';
import PanelNav from '../../../components/nav/PanelNav';
import { BackActionMobile } from '../../../components/nav/styled';
import { NumericFormat } from '../../../components/numericFormat/NumericFormat';

// Styled components
import OButton from '../../../styled/button';
import { Wrapper, Inner, Separator, ButtonWrapper } from '../../../styled/wrappers';
import { PackageCard, PackageCardItem, SelectedIndicator } from '../styled';

// Models
import PaymentModel from '../../../../models/payments';

// Locales
import I18n from '../../../../i18n';

const NavItemsOptions =  {
    render: () => (
        <>
            <BackActionMobile as={ Link } to="/panel">
                <Icon name="arrow left" size="large" />
            </BackActionMobile>
            <span className="paragraph white regular m-auto">{ I18n.t('buttons.actions.buy') }</span>
        </>
    )
};

const packages = [{
    id: 1,
    priceEUR: 50
}, {
    id: 2,
    priceEUR: 100
}, {
    id: 3,
    priceEUR: 150
}, {
    id: 4,
    priceEUR: 250
}, {
    id: 5,
    priceEUR: 500
}, {
    id: 6,
    priceEUR: 1000
}, {
    id: 7,
    priceEUR: 2500
}, {
    id: 8,
    priceEUR: 5000
}];

class CheckoutStep1 extends Component {

    constructor(props) {

        super(props);

        this.state = {
            selectedPackage: null,
            ygbCurrentValue: 0
        };

    }

    componentDidMount() {

        this.getData();

    }

    getData = async () => {

        try {

            const { data } = await PaymentModel.getStagesInfo();

            const defaultPackage = {
                id: 1,
                priceEUR: 50,
                tokens: Math.round(50 / data.price)
            };

            const selectedPackage = window.localStorage.getItem('pk') ? JSON.parse(window.localStorage.getItem('pk')) : defaultPackage;
            window.localStorage.setItem('pk', JSON.stringify(selectedPackage));

            this.setState({ ygbCurrentValue: data.price, selectedPackage });


        } catch (error) {

            console.error('error', error);

        }

    }

    setSelected = packageId => {

        const { ygbCurrentValue } = this.state;

        const selectedPackage = _.find(packages, pk => pk.id === packageId);
        selectedPackage.tokens = Math.round(selectedPackage.priceEUR / ygbCurrentValue);

        this.setState({ selectedPackage }, () => window.localStorage.setItem('pk', JSON.stringify(selectedPackage)));

    }

    render() {

        const { selectedPackage, ygbCurrentValue } = this.state;

        return (
            <Wrapper>
                <PanelNav active="" innerNavItems={ NavItemsOptions } />
                <Inner>
                    <Separator />
                    <Goback to="/panel" />
                    <p className="paragraph alignCenter">{ I18n.t('payment.selectPackage') }</p>
                    <div className="row">
                        { packages.map(({ priceEUR, id }) => (
                            <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3" key={ id }>
                                <PackageCard onClick={ ()=> this.setSelected(id) }>
                                    <PackageCardItem $selected={ selectedPackage?.id === id }>
                                        <SelectedIndicator>
                                            <Icon name="check" />
                                        </SelectedIndicator>
                                        <span className="paragraph"><NumericFormat value={ priceEUR } type="eur" /></span>
                                        <span className="paragraph regular"><NumericFormat value={ priceEUR / ygbCurrentValue } type="token" suffix="YGB"/> </span>
                                        <span className="caption regular">{ ygbCurrentValue } €/YGB</span>
                                    </PackageCardItem>
                                </PackageCard>
                            </div>
                        )) }
                    </div>
                    <ButtonWrapper reverse>
                        <OButton terciary upper color="#fff" disabled={ !selectedPackage } onClick={ ()=> this.props.history.push('/checkout/methods') }>
                            <span>{ I18n.t('buttons.actions.continue') }</span>
                        </OButton>
                    </ButtonWrapper>
                    <Separator xL/>
                </Inner>
                <BottomNav active="" />
            </Wrapper>
        );

    }

}

export default CheckoutStep1;