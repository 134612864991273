import React from 'react'
import NumberFormat from 'react-number-format';

//? INFO https://github.com/s-yadav/react-number-format

function NumericHandler({ type, value, ...rest }) {

    switch(type) {

        case 'token':

            return <NumberFormat value={ (value || 0).toFixed(0) } displayType='text' decimalScale={ 0 } thousandSeparator={ true } { ...rest } />

        case 'eur':

            return <NumberFormat value={ value } displayType='text' decimalScale={ 2 } fixedDecimalScale={ true } thousandSeparator={ true } suffix='€' { ...rest } />

        case 'usd':

            return <NumberFormat value={ value } displayType='text' decimalScale={ 2 } fixedDecimalScale={ true } thousandSeparator={ true } prefix='$' { ...rest } />

        default:

            return <NumberFormat value={ value } { ...rest } />

    }

}

export function NumericFormat(props) {

    return <NumericHandler {...props} />

}