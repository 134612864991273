import React, { Component } from 'react';
import { Route } from 'react-router-dom';

///styled components
import BottomNav from '../../components/bottomNav/BottomNav';
import { Wrapper } from '../../styled/wrappers'

//routes
import ProfileAccount from './components/ProfileAccount';
import ProfileEmail from './components/ProfileEmail';
import ProfilePreferences from './components/ProfilePreferences';
import ProfileSecurity from './components/ProfileSecurity';

class Profile extends Component {
    render() {
        return (
            <>
                <Wrapper>
                    <Route path="/profile/account" exact component={ ProfileAccount } />
                    <Route path="/profile/security" exact component={ ProfileSecurity } />
                    <Route path="/profile/email" exact component={ ProfileEmail } />
                    <Route path="/profile/preferences" exact component={ ProfilePreferences } />
                </Wrapper>
                <BottomNav active="" />
            </>
        );
    }
}

export default Profile;