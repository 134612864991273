import styled, {css} from 'styled-components/macro';
import PanelBg from '../../../assets/img/fondo_panel.jpg';
import { flex, wrap, alignCenter, justifyCenter, alignStart } from '../../../styled/css';
import { device } from '../../../styled/mbreakpoints';

const Hero = styled.div`

    position:relative;
    min-height:15rem;
    //background-image:url(${PanelBg});
    //background-size:cover;
   // background-repeat:no-repeat;
    //background-position:center center;
    background:var(--brand-primary); 

`;

const InfoBlockHeader = styled.div`
    border-bottom: 1px solid rgba(229, 229, 229, 0.77);
    padding:1rem 1.5rem;
`;

const InfoBlock = styled.div`
    background: rgba(241, 244, 244, 0.77);
    border-radius: 8px;
    overflow:hidden;
    ${ p => p.$flex && flex};
    ${ p => p.$fWrap && wrap };
    ${ p => p.$alignCenter && alignCenter };
    ${ p => p.$justifyCenter && justifyCenter };
    ${ p => p.$flexStart && alignStart };
    ${({bordered}) => bordered && css`
        border: 1px solid #000000;
        background: #fff;
        ${InfoBlockBody} {
            padding:3rem 2rem;
        }
    `};
`;

const RefBlock = styled.div`

    display:flex;
    align-items:center;
    justify-content:flex-end;
    border: 1px solid #000000;
    padding:2rem;
    border-radius: 8px;
    flex-direction:column;
    overflow:hidden;
    @media ${ device.tablet } { 
        flex-direction:row;
        padding:3rem 2rem;
    }
`;

const FlexCol = styled.div`
    flex: 1;
    width:100%;
    ${ p => p.$flex && flex};
    justify-content: flex-start;
    align-items:center;
    margin-bottom:0.5rem;
    margin-top: 0.5rem;
    width:100%;
    flex-wrap:wrap;
    @media ${ device.tablet } { 
        margin-bottom:0;
        margin-top: 0;
        justify-content: ${ p => p.$jContent && p.$jContent};
    }
`;

const InfoBlockBody = styled.div`
    padding:1rem 1.5rem;

`;

const InfoBlockBodyDivisors = styled.div`
    background: #fff;
    padding:1rem;
    margin-bottom:1rem;
`;

const ActionButton = styled.button`
    border: none;
    outline:0;
    background:transparent;
`;

const IconButton = styled(ActionButton)`
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
    margin:0;
    cursor:pointer;
    i {
        width:auto;
        height:auto;
        margin:0;
        &:before {
            color:var(--brand-primary);
        }
    }

    @media ${ device.tablet } { 
        margin:0 auto;
    }

`;

const MessageLabel = styled.div`
    padding:0.2rem;
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin: 0.5rem 0;
    i{
        margin:0 0.5rem;
        width:auto;
        height:auto;
        &::before{
            color:var(--brand-secondary);
        }
    }
`;

const HeaderIcon = styled.div`
    display:flex;
    align-items:center;
    img {
        width: 3rem;
        flex: none;
    }
    span {
        margin-left: 0.5rem;
        // font-size: 2rem;
        // font-family: var(--font-primary);
        // color:var(--brand-primary);
    }
`;

export {
    Hero,
    InfoBlock,
    InfoBlockHeader,
    InfoBlockBody,
    InfoBlockBodyDivisors,
    ActionButton,
    IconButton,
    MessageLabel,
    FlexCol,
    RefBlock,
    HeaderIcon
}