//libraries & components libraries
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import queryString from 'query-string';

//componentes
import CustomInput from '../../../components/form/input';
import CustomCheckBox from '../../../components/form/checkbox';

//styled components
import { LoginRemember, ForgotPassword } from './styled';
import { AuthBox, AuthCol, AuthCTARegister, AuthHeader, AuthRow, AuthSeparator, AuthTitle } from '../styled';

//redux
import { loginUser, closeSession } from '../../../../redux-store/auth';
import { closeLoader, openLoader } from '../../../../redux-store/loader';
import { openGenericModal, closeGenericModal } from '../../../../redux-store/genericModal';

//assets
import I18n from '../../../../i18n';
import OButton from '../../../styled/button';
import Messager from '../../../components/messager/Messager';

class Login extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            hasError: false,
            codeError: -1,
        };

    }

    componentDidMount() {

        this.checkExpiredSession();
        
    }

    checkExpiredSession = () => {
        
        const { openGenericModal, closeGenericModal, closeSession } = this.props;

        if (_.get(this.props, 'location.search', false)) {

            const UrlQueryStrings = this.props.location.search;
            const queryValues = queryString.parse(UrlQueryStrings);

            if (queryValues.expired) {

                openGenericModal({
                    type: 'simple',
                    title:{
                        text: I18n.t('popups.alert'),
                        classes: ['heading-2']
                    },
                    description:{
                        text: I18n.t('popups.sessionExpired'),
                        classes: ['paragraph', 'regular']
                    },
                    buttons:[
                        {
                            text: I18n.t('buttons.actions.understood'),
                            callback: ()=> {
        
                                closeGenericModal();
        
                            },
                            options: {
                                terciary: true,
                                color: '#fff',
                                fluid: true,
                                upper: true
                            }
                        }
        
                    ]
                });

            } else if (queryValues.logout) {

                closeSession();

            }

        }

    }

    onForgotPassword = () => this.props.history.push(`/auth/recovery/step/1`);

    onRegister = () => this.props.history.push('/auth/register');

    onLogin = async credentials => {

        const { openLoader, closeLoader, history, loginUser } = this.props;
        const formValues = { email: _.trim(credentials.email), password: credentials.password, remember: credentials.remember };

        try {

            openLoader();
            await loginUser(formValues);
            history.push('/panel');

        } catch (codeError) {

            this.setState({ hasError: true, codeError: 'error-login' });
            closeLoader();

        } finally {
            closeLoader();
        }
        
    };

    render() {

        const { codeError, hasError } = this.state;

        return (
            <AuthRow>
                <AuthCol>
                    <AuthBox>
                        <AuthHeader column>
                            <AuthTitle><span>{I18n.t('login.title')}</span></AuthTitle>
                        </AuthHeader>
                        <AuthSeparator />
                        <Messager code={codeError} active={hasError} $variant={'incorrect'}/>
                        <Form name="loginForm" onSubmit={this.props.handleSubmit(this.onLogin)} noValidate>
                            <AuthSeparator />
                            <Field
                                component={ CustomInput }
                                placeholder={ I18n.t('login.email') }
                                name="email"
                                fieldClasses="y-input default small"
                                label={ I18n.t('login.email') }
                                restrictions={ [{ trim: true }] } />
                            <Field
                                component={ CustomInput }
                                placeholder={ "" }
                                name="password"
                                type="password"
                                fieldClasses="y-input default small"
                                label={ I18n.t('login.password') } />
                            <LoginRemember>
                                <div>
                                    <Field
                                        component={ CustomCheckBox }
                                        name='remember'
                                        fieldClasses="y-checkbox"
                                        label={ I18n.t('login.remember') } />
                                </div>
                                <ForgotPassword onClick={ this.onForgotPassword }><span className="labeling regular c-pointer">{I18n.t('login.forgotYourPassword')}</span></ForgotPassword>
                            </LoginRemember>
                            <AuthSeparator />
                                <OButton upper type="submit" fluid color="#FFF" terciary>
                                    <span>{I18n.t('buttons.actions.login')}</span>
                                </OButton>
                            <AuthSeparator />
                            <AuthCTARegister>
                                <span className="labeling regular">{I18n.t('login.noAccountYet')}</span>
                                <span className="labeling regular brand c-pointer" onClick={this.onRegister}>{I18n.t('login.registerNow')}</span>
                            </AuthCTARegister>
                        </Form>
                    </AuthBox>
                </AuthCol>
            </AuthRow>
        );

    }

}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');

    }

    if (_.isEmpty(formValues.password)) {

        errors.password = I18n.t('validations.required');

    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');
        
    }

    return errors;

};
 
export default reduxForm({
    form: 'loginForm',
    touchOnBlur: true,
    touchOnChange: false,
    validate,
    initialValues: {
        email: '',
        password: '',
        remember: false
    }
})(connect(null, {  openLoader, closeLoader, loginUser, openGenericModal, closeGenericModal, closeSession })(Login));