import AxiosService from '../../utils/AxiosService';
import Config from '../../config';

const acceptOrDeclineRequestYGB = async (requestId, action) => await AxiosService.post(Config.server.api.paymentApi + `io/balance/request/${ requestId }/${ action }`, {}, true);
const createInvestment = async payload => await AxiosService.post(`${ Config.server.api.paymentApi }io/investments`, payload, true);
const getBalance = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/balance', true);
const getTransactionsMovements = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/balance/movements', true);
const getAffiliateCommissions = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/commissions', true);
const getStagesInfo = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/stages', false);
const getUserInvestment = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/investments/user', true);
const getUserAsignments = async () => await AxiosService.get(Config.server.api.paymentApi + 'io/user/tokens', true);
const registerAffiliateClick = async ({referrerUserId}) => await AxiosService.post(`${ Config.server.api.paymentApi }io/commissions/${ referrerUserId }/ygb-affiliate-click/none`, {}, true);

const exports = {
    acceptOrDeclineRequestYGB,
    createInvestment,
    getBalance,
    getAffiliateCommissions,
    getTransactionsMovements,
    getStagesInfo,
    getUserInvestment,
    getUserAsignments,
    registerAffiliateClick
};

export default exports;