import styled, {css} from 'styled-components/macro';
import bg from '../../../../ui/assets/img/fondo_usability.png';


const AffiliateCard = styled.div`

    display:flex;
    align-items:center;
    justify-content:center;
    border-radius: 5px;
    overflow:hidden;
    flex-direction:column;
    padding:1.5rem;
    ${({$bordered}) => $bordered && css`
        background:#fff;
        border:1px solid var(--brand-primary);
    `};

    ${({$withBg}) => $withBg && css`
        background-size:cover;
        background-image:url(${bg});
        background-repeat:no-repeat;
        background-position:center center;
    `};

`;

export {
    AffiliateCard
}