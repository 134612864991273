// Third party libraries
import _ from 'lodash';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Icon } from 'semantic-ui-react';

// Components
import PanelNav from '../../components/nav/PanelNav';
import BottomNav from '../../components/bottomNav/BottomNav';
import { NumericFormat } from '../../components/numericFormat/NumericFormat';
import { Footer } from '../../components/footer/Footer';

// Styled components
import { InfoBlock, InfoBlockHeader, InfoBlockBody, InfoBlockBodyDivisors, ActionButton, IconButton, MessageLabel, RefBlock, FlexCol, HeaderIcon } from './styled';
import OButton from '../../styled/button';
import { Wrapper, Inner, Separator } from '../../styled/wrappers'

// Redux
import { openGenericModal, closeGenericModal } from '../../../redux-store/genericModal';
import { openLoader, closeLoader } from '../../../redux-store/loader';

// Models
import PaymentModel from '../../../models/payments';

// Locales
import I18n from '../../../i18n';

// Assets
import YGBIcon from '../../../ui/assets/img/coin_3.png';

class Panel extends Component {

    constructor(props) {

        super(props);

        this.state = {
            balance: 0,
            copiedLink: false,
            stagesInfo: {},
            affiliation: 0,
            investment: 0,
            investedEUR: 0,
            preico: 0,
            teamTokens: 0,
            prizeTokens: 0
        };

    }

    componentDidMount() {

        this.getInfos();

    }

    getInfos = async () => {

        const { openLoader, closeLoader } = this.props;

        try {

            openLoader();

            const stages = await PaymentModel.getStagesInfo();

            const investmentsData = await PaymentModel.getUserInvestment();
            const investedEUR = _.sumBy(investmentsData.data, 'amount');

            const balanceData = await PaymentModel.getBalance();
            const { affiliation, balance, investment, preico } = balanceData.data;

            const userAsignments = await PaymentModel.getUserAsignments();

            const teamTokens = _.chain(userAsignments.data).filter(({concept}) => concept === 'team').map(({amount}) => ({ tokens: amount })).sumBy('tokens').value();
            const prizeTokens = _.chain(userAsignments.data).filter(({concept}) => concept === 'prize').map(({amount}) => ({ tokens: amount })).sumBy('tokens').value();

            this.setState({ affiliation, balance, investedEUR, investment, preico, stagesInfo: stages.data, teamTokens, prizeTokens });

        } catch (error) {

            console.error('error', error)

        } finally {

            closeLoader();

        }

    }

    onShowInfo = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            size: 'large',
            title: {
                text: I18n.t('popups.info'),
                classes: ['heading-3']
            },
            description: {
                text: I18n.t('panel.popup.copyInfo'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('buttons.actions.understood'),
                callback: closeGenericModal,
                options: {
                    terciary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    onCopyLink = copiedLink => this.setState({ copiedLink });

    openWarningInfo = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('popups.info'),
                classes: ['heading-3']
            },
            description: {
                text: I18n.t('panel.popup.wariningInfo'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('buttons.actions.understood'),
                callback: closeGenericModal,
                options: {
                    terciary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    openWithdrawnInfo = () => {

        const { openGenericModal, closeGenericModal } = this.props;

        openGenericModal({
            type: 'simple',
            title: {
                text: I18n.t('popups.notice'),
                classes: ['heading-3']
            },
            description: {
                text: I18n.t('panel.popup.withdrawnInfo'),
                classes: ['paragraph', 'regular']
            },
            buttons: [{
                text: I18n.t('buttons.actions.understood'),
                callback: closeGenericModal,
                options: {
                    terciary: true,
                    color: '#fff',
                    fluid: true,
                    upper: true
                }
            }]
        });

    }

    render() {

        const { affiliation, balance, copiedLink, investment, investedEUR, preico, teamTokens, prizeTokens } = this.state;
        const { userSession } = this.props;

        const isDisabled = investedEUR < 100 && !userSession.affiliateOn;

        const linkValue = `${window.location.origin}/#/earn/?ref=${userSession._id}`;

        const { stagesInfo: { stage = 0, round = 0, phase = 0, price = 0, tokenAmount = 0, stageTotalTokensSold = 0, totalTokensSold = 0 }} = this.state;

        return (
            <Wrapper>
                <PanelNav active="panel" />
                <Inner>
                <Separator xL/>
                <HeaderIcon>
                    <img src={ YGBIcon } alt="YGBIcon" />
                    <span className="heading-2">Yogabot YGB</span>
                </HeaderIcon>
                    <Separator />
                    <InfoBlock>
                        <InfoBlockHeader>
                            <p className="paragraph regular">{ I18n.t('panel.section1.title') }</p>
                        </InfoBlockHeader>
                        <InfoBlockBody>
                            <div className="row">
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <div>
                                        <p className="paragraph regular" style={{ marginBottom: 0 }}>{ I18n.t('panel.section1.progress', { stage, round }) }</p>
                                        <p className="caption regular">{ I18n.t('panel.section1.phase', { phase }) }</p>
                                        <p className="paragraph regular"><span className="heading-3 regular"><NumericFormat value={ stageTotalTokensSold } type="token" /></span> { I18n.t('panel.of') } <NumericFormat value={ tokenAmount } type="token" /></p>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <div>
                                        <p className="paragraph regular" style={{ marginBottom: 0 }}>{ I18n.t('panel.section1.currentPrice') }</p>
                                        <p className="paragraph regular"><span className="heading-3 regular">{ price } </span>€/YGB</p>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3">
                                    <div>
                                        <p className="paragraph regular" style={{ marginBottom: 0 }}>{ I18n.t('panel.section1.totalYGB') }</p>
                                        <p className="heading-3 regular"><NumericFormat value={ totalTokensSold } type="token" /></p>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6 col-lg-3 col-xl-3" onClick={ () => this.props.history.push('/checkout/packages') }>
                                    <OButton fluid terciary upper color="#fff"><span>{ I18n.t('buttons.actions.buy') }</span></OButton>
                                </div>
                            </div>
                        </InfoBlockBody>
                    </InfoBlock>
                    <Separator />
                    <InfoBlock>
                        <InfoBlockHeader>
                            <p className="paragraph regular">{ I18n.t('panel.section2.title') }</p>
                        </InfoBlockHeader>
                        <InfoBlockBody>
                            <InfoBlockBodyDivisors>
                                <p className="paragraph regular">{ I18n.t('panel.section2.balance') }</p>
                                <div className="row">
                                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                        <p className="paragraph regular"><span className="heading-3 regular">
                                            <NumericFormat value={ balance } type="token" /></span> / <NumericFormat value={ balance * price} type="eur" />
                                        </p>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <div className="row">
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <ActionButton type="button" onClick={ () => this.props.history.push('/checkout/packages') }>
                                                    <span className="paragraph regular asLink">{ I18n.t('buttons.actions.buy') }</span>
                                                </ActionButton>
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                <ActionButton type="button" onClick={ this.openWithdrawnInfo }>
                                                    <span className="paragraph regular asLink">{ I18n.t('buttons.actions.withdrawn') }</span>
                                                </ActionButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </InfoBlockBodyDivisors>
                            { preico > 0 &&
                                <InfoBlockBodyDivisors>
                                    <p className="paragraph regular">{ I18n.t('panel.section2.preIcoToken') }</p>
                                    <div className="row">
                                        <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                            <p className="paragraph regular">
                                                <span className="heading-3 regular">
                                                    <NumericFormat value={ preico } type="token" />
                                                </span> / <NumericFormat value={ preico * price } type="eur" />
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        </div>
                                    </div>
                                </InfoBlockBodyDivisors>
                            }
                            <InfoBlockBodyDivisors>
                                <p className="paragraph regular">{ I18n.t('panel.section2.boughtTokens') }</p>
                                <div className="row">
                                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                        <p className="paragraph regular">
                                            <span className="heading-3 regular">
                                                <NumericFormat value={ investment } type="token" />
                                            </span> / <NumericFormat value={ investment * price } type="eur" />
                                        </p>
                                    </div>
                                    <div className="col-sm-3  col-md-3 col-lg-3  col-xl-3">
                                        <ActionButton type="button" onClick={ () => this.props.history.push('/panel/transactions/history') }>
                                            <span className="caption regular asLink">{ I18n.t('panel.section2.seeHistory') }</span>
                                        </ActionButton>
                                    </div>
                                </div>
                            </InfoBlockBodyDivisors>
                            <InfoBlockBodyDivisors>
                                <p className="paragraph regular">{ I18n.t('panel.section2.gainToken') }</p>
                                <div className="row">
                                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                        <p className="paragraph regular">
                                            <span className="heading-3 regular">
                                                <NumericFormat value={ affiliation } type="token" />
                                            </span> / <NumericFormat value={ affiliation * price } type="eur" />
                                        </p>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        <ActionButton type="button" onClick={ () => this.props.history.push('/panel/affiliates') }>
                                            <span className="caption regular asLink">{ I18n.t('panel.section2.seeAffiliatesPanel') }</span>
                                        </ActionButton>
                                    </div>
                                </div>
                            </InfoBlockBodyDivisors>
                            { teamTokens > 0 &&
                                <InfoBlockBodyDivisors>
                                    <p className="paragraph regular">{ I18n.t('panel.section2.gainTokensTeam') }</p>
                                    <div className="row">
                                        <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                            <p className="paragraph regular">
                                                <span className="heading-3 regular">
                                                    <NumericFormat value={ teamTokens } type="token" />
                                                </span> / <NumericFormat value={ teamTokens * price } type="eur" />
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        </div>
                                    </div>
                                </InfoBlockBodyDivisors>
                            }
                            { prizeTokens > 0 &&
                                <InfoBlockBodyDivisors>
                                    <p className="paragraph regular">{ I18n.t('panel.section2.gainTokensPrize') }</p>
                                    <div className="row">
                                        <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                            <p className="paragraph regular">
                                                <span className="heading-3 regular">
                                                    <NumericFormat value={ prizeTokens } type="token" />
                                                </span> / <NumericFormat value={ prizeTokens * price } type="eur" />
                                            </p>
                                        </div>
                                        <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                        </div>
                                    </div>
                                </InfoBlockBodyDivisors>
                            }
                        </InfoBlockBody>
                    </InfoBlock>
                    <Separator xL />
                        <p className="paragraph">{I18n.t('panel.section3.title')}</p>
                        <p className="paragraph regular">{I18n.t('panel.section3.p1')}</p>
                        <p className="paragraph regular" dangerouslySetInnerHTML={{__html: I18n.t('panel.section3.p2')}}></p>
                    <Separator />
                    <RefBlock bordered>
                        { !isDisabled &&
                            <FlexCol>
                                <p className="paragraph regular wbk" style={{ marginBottom: 0 }}>{ linkValue }</p>
                            </FlexCol>
                        }
                        <FlexCol $jContent="flex-end" $flex>
                        { !isDisabled &&
                            <CopyToClipboard text={ linkValue } onCopy={ () => this.onCopyLink(true) }>
                                <OButton upper color="#1fd5b9" selected><span>{ I18n.t('buttons.actions.copyCode') }</span></OButton>
                            </CopyToClipboard>
                        }
                        { copiedLink &&
                            <MessageLabel>
                                <span className="caption regular">{ I18n.t('buttons.actions.copiedLink') }</span>
                                <Icon name="check circle" />
                            </MessageLabel>
                        }
                        { isDisabled &&
                            <>
                                <IconButton onClick={ () => this.openWarningInfo() } style={{ marginRight: '1rem' }}>
                                    <Icon name="warning sign" size="large" />
                                </IconButton>
                                <OButton  upper color="#1fd5b9" selected disabled><span>{ I18n.t('buttons.actions.copyCode') }</span></OButton>
                            </>
                        }
                        </FlexCol>
                    </RefBlock>
                    <Separator />
                    <div className="c-pointer" onClick={ this.onShowInfo }>
                        <Icon name="info circle" size="large" />
                        <span className="paragraph regular">{ I18n.t('buttons.actions.moreInfo') }</span>
                    </div>
                    <Separator xL />
                </Inner>
                <Footer />
                <BottomNav active="panel" />
            </Wrapper>
        );

    }

}

const mapStateToProps = state => {

    return {
        userSession: _.get(state, 'auth.userSession', {})
    };

};

export default connect(mapStateToProps, { openGenericModal, closeGenericModal, openLoader, closeLoader })(Panel);