import React, { Component } from 'react';
import PanelNav from '../../../components/nav/PanelNav';
import { Form } from 'semantic-ui-react';
import { Field, reduxForm } from 'redux-form';
import { Inner, Separator } from '../../../styled/wrappers';
import { WrapperProfile, ProfileAside, ProfileContent } from '../styled';
import ProfileMenu from './menu/ProfileMenu';
import OButton from '../../../styled/button';
import { openToast } from '../../../../redux-store/toast';
import CustomInput from '../../../components/form/input';
import SecurityModel from '../../../../models/security/security';
import I18n from '../../../../i18n';
import { connect } from 'react-redux';
import _ from 'lodash';

class ProfileEmail extends Component {

    onSaveEmail = async (email) => {

        try {
             
            await SecurityModel.changeEmail(email);
            
            openToast({message: I18n.t('messages.dataSaved'), type: 'success'});

        } catch (codeError) {
            
            openToast({ message: I18n.t(`status.code.401`), type: 'error' });

        } 

    }

    render() {
        return (
            <>
                <PanelNav active="profileEmail" />
                <Inner>
                    <Separator xL />
                    <WrapperProfile>
                        <ProfileAside>
                            <ProfileMenu active="profileEmail" />
                        </ProfileAside>
                        <ProfileContent>
                        <p className="heading-3">{I18n.t('profile.changeEmail')}</p>
                        <Form name="profileEmailForm" noValidate onSubmit={this.props.handleSubmit(this.onSaveEmail)}>
                        <Field
                            component={ CustomInput }
                            placeholder={ I18n.t('profile.email') }
                            name="email"
                            fieldClasses="label-primary"
                            label={ '' } />
                            <OButton upper type="submit" color="#FFF" terciary>
                                <span>{I18n.t('buttons.actions.saveChanges')}</span>
                            </OButton>
                        </Form>
                        </ProfileContent>
                    </WrapperProfile>
                </Inner>
            </>
        );
    }
}

const validate = formValues => {

    const errors = {};

    if (_.isEmpty(formValues.email)) {

        errors.email = I18n.t('validations.required');
        
    }

    if (!_.isEmpty(formValues.email) && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formValues.email)) {

        errors.email = I18n.t('validations.emailInvalid');
        
    }

    return errors;

};

export default reduxForm({
    form: 'profileEmailForm',
    touchOnBlur: true,
    touchOnChange: false,
    enableReinitialize: true,
    validate,
})(connect(null, { })(ProfileEmail));